import { axiosClient } from "../client";
import { formatString } from "@/helpers/formatting";
import endpoints from "../endpoints";
import type {
  IWorkflow,
  CreateWorkflowTemplate,
  IWorkflowBlock,
  WorkflowsViewFilters
} from "@/models/workflows";
import type { IPaginatedResponse } from "@/models/common";
import type {
  ApplicationWorkflow,
  IDiscoveryCallAnswer,
  IDiscoveryCallQuestion
} from "@/models/applications";
import type { GetOfferTemplatesParams, OfferTemplate } from "@/models/offers";

class WorkflowApiService {
  async getWorkflowTemplates(params: WorkflowsViewFilters = {}) {
    const response = await axiosClient.get<IPaginatedResponse<IWorkflow>>(
      endpoints.WORKFLOW_TEMPLATES.ALL,
      { params }
    );
    return response.data;
  }

  async filterWorkflowTemplates(filters: WorkflowsViewFilters = {}) {
    const response = await axiosClient.post<IPaginatedResponse<IWorkflow>>(
      endpoints.WORKFLOW_TEMPLATES.FILTER_TEMPLATES,
      filters
    );
    return response.data;
  }
  async filterWorkflows(
    params: {
      attribute?: "stage_name" | "status" | "sub_status";
      values?: string[];
      search?: string;
    } = {}
  ) {
    const response = await axiosClient.get<IPaginatedResponse<IWorkflow>>(
      endpoints.WORKFLOW_TEMPLATES.FILTER,
      { params }
    );
    return response.data;
  }

  async createWorkflowTemplate(template: CreateWorkflowTemplate) {
    const response = await axiosClient.post<{ data: IWorkflow }>(
      endpoints.WORKFLOW_TEMPLATES.ALL,
      template
    );
    return response.data.data;
  }

  async updateWorkflowTemplate(
    templateId: IWorkflow["id"],
    payload: Partial<IWorkflow>
  ) {
    const response = await axiosClient.patch<{ data: IWorkflow }>(
      formatString(endpoints.WORKFLOW_TEMPLATES.SINGLE, templateId),
      payload
    );
    return response.data.data;
  }

  async getWorkflowTemplate(templateId: IWorkflow["id"]) {
    const response = await axiosClient.get<{ data: IWorkflow }>(
      formatString(endpoints.WORKFLOW_TEMPLATES.SINGLE, templateId)
    );
    return response.data.data;
  }

  async toggleWorkflowTemplateGlobalStatus(templateId: IWorkflow["id"]) {
    const response = await axiosClient.put<{ data: IWorkflow }>(
      formatString(endpoints.WORKFLOW_TEMPLATES.TOGGLE_GLOBAL, templateId)
    );
    return response.data.data;
  }

  async deleteWorkflowTemplate(templateId: IWorkflow["id"]) {
    const response = await axiosClient.delete<{ data: { deleted: boolean } }>(
      formatString(endpoints.WORKFLOW_TEMPLATES.SINGLE, templateId)
    );
    return response;
  }

  async sumbitToNextWorkflowPhase(applicationId: string) {
    const response = await axiosClient.put(
      formatString(
        endpoints.WORKFLOW_TEMPLATES.SUBMIT_TO_NEXT_STAGE,
        applicationId
      )
    );
    return response.data;
  }

  async rollbackWorkflowPhase(applicationId: string) {
    const response = await axiosClient.put(
      formatString(
        endpoints.WORKFLOW_TEMPLATES.ROLLBACK_STAGE_PROGRESS,
        applicationId
      )
    );
    return response.data;
  }

  async getWorkflowSnapshot(applicationId: string) {
    const response = await axiosClient.get(
      formatString(
        endpoints.WORKFLOW_TEMPLATES.WORKFLOW_SNAPSHOT,
        applicationId
      )
    );
    return response.data.data;
  }

  async getWorkflowStatuses(applicationId: string) {
    const response = await axiosClient.get(
      formatString(endpoints.WORKFLOW_TEMPLATES.WORKFLOW_STATUS, applicationId)
    );
    return response.data.data;
  }

  async updateWorkflowSnapshotFields(appId: string, data: unknown) {
    const response = await axiosClient.put(
      formatString(endpoints.WORKFLOW_TEMPLATES.UPDATE_APP, appId),
      data
    );
    return response.data.data.fields;
  }

  async updateDiscoveryCallAnswers(id: string, data: unknown) {
    const response = await axiosClient.put<{ data: IDiscoveryCallAnswer[] }>(
      formatString(endpoints.WORKFLOW_TEMPLATES.WORKFLOW_DISCOVERY_ANSWERS, id),
      data
    );
    return response.data;
  }

  async createDiscoveryCallQuestions(
    appId: string,
    data: IDiscoveryCallQuestion
  ) {
    await axiosClient.post(
      formatString(
        endpoints.WORKFLOW_TEMPLATES.WORKFLOW_DISCOVERY_QUESTIONS,
        appId
      ),
      data
    );
  }

  async deleteDiscoveryCallQuestion(appId: string, data: { id: string }) {
    await axiosClient.delete(
      formatString(
        endpoints.WORKFLOW_TEMPLATES.WORKFLOW_DISCOVERY_QUESTIONS,
        appId
      ),
      {
        data
      }
    );
  }

  async getFieldsData(appId: string, blocks?: IWorkflowBlock["id"][]) {
    const response = await axiosClient.get<{ data: ApplicationWorkflow }>(
      formatString(endpoints.WORKFLOW_TEMPLATES.WORKFLOW_FIELDS_DATA, appId),
      { params: { blocks } }
    );
    return response.data.data.fields;
  }

  async duplicateWorkflowTemplate(templateId: IWorkflow["id"]) {
    const response = await axiosClient.post<{ data: IWorkflow }>(
      formatString(endpoints.WORKFLOW_TEMPLATES.DUPLICATE_TEMPLATE, templateId)
    );
    return response.data.data;
  }

  async getWorkflowOfferTemplates(
    templateId: IWorkflow["id"],
    params?: GetOfferTemplatesParams
  ) {
    const response = await axiosClient.get<{ data: OfferTemplate[] }>(
      formatString(endpoints.WORKFLOW_TEMPLATES.OFFER_TEMPLATES, templateId),
      { params }
    );
    return response.data;
  }
}

const workflowApiService = new WorkflowApiService();
export default workflowApiService;
