import optionsApiService from "@/services/modules/options";
import {
  CUSTOM_ATTRIBUTE_V2_SCHEMA_VERSION,
  DEFAULT_PER_PAGE,
  EXPERIAN_BUSINESS_OWNER_PROFILE,
  EXPERIAN_BUSINESS_OWNER_PROFILE_PREMIER,
  FILE_MAPPING,
  TERM_LENGTH_OPTIONS
} from "@/helpers/constants";
import type { IDashboardSettings, TermLengthOption } from "@/models/common";
import type {
  AttributeOptions,
  EmailTemplateOptions,
  IExpandedServiceData,
  IIndustry,
  IOptions,
  IOptionsState,
  IOptionsApplication,
  IOptionsWorkflowTemplates,
  IPlacementDeclineSubReason,
  ISubIndustry,
  SubIndustryWithParent,
  RiskTierOption,
  RiskTier,
  ContractTemplateOptions,
  OfferOptions,
  EmailTemplateOption
} from "@/models/options";
import type { IRootState } from "@/models/state";
import type { ActionTree, GetterTree, MutationTree } from "vuex";
import i18n from "@/i18n";
import { formatMoney, formatMoneyRange } from "@/helpers/formatting";
import isEmpty from "lodash/isEmpty";
import set from "lodash/set";
import type { OfferTemplateField } from "@/models/offers";
import { useContractBuilderStore } from "@/stores/contractBuilder";
import { convertToArray } from "@/helpers/common";
import { TEMPLATE_MODES } from "@/helpers/constants/contractBuilder";
import { CONTRACT_LINK } from "@/helpers/constants/communicationLogs";

const t = i18n.global.t;

const HAVENT_REGISTERED_OPTION = "time_in_business_8";

type OptionName =
  | "options"
  | "applicationsOptions"
  | "workflowTemplatesOptions"
  | "contractTemplatesOptions"
  | "settings"
  | "offerTemplatesOptions";

type LocalStorageOptionLoaded<TName> = TName extends "options"
  ? IOptions
  : TName extends "applicationsOptions"
    ? IOptionsApplication
    : TName extends "workflowTemplatesOptions"
      ? IOptionsWorkflowTemplates
      : TName extends "settings"
        ? IDashboardSettings
        : never;

type LocalStorageOptionLoadedReturn<TName> = TName extends undefined
  ? IOptions
  : LocalStorageOptionLoaded<TName> | null;

export const loadLocalStorageOptions = <
  TName extends OptionName | undefined = undefined
>(
  name?: TName
): LocalStorageOptionLoadedReturn<TName> => {
  const value = localStorage.getItem(name ?? "options");
  return (
    value ? JSON.parse(value) : null
  ) as LocalStorageOptionLoadedReturn<TName>;
};

const state: IOptionsState = {
  all: loadLocalStorageOptions(),
  application: loadLocalStorageOptions("applicationsOptions"),
  workflow_templates: loadLocalStorageOptions("workflowTemplatesOptions"),
  email_templates: null,
  dashboardSettings: loadLocalStorageOptions("settings"),
  contract_templates: loadLocalStorageOptions("contractTemplatesOptions"),
  attributeOptions: {},
  offerTemplateOptions: loadLocalStorageOptions("offerTemplatesOptions")
};

const mutations: MutationTree<IOptionsState> = {
  setOptions(state, data: IOptions) {
    localStorage.setItem("options", JSON.stringify(data));
    state.all = data;
  },
  setEmailTemplatesOptions(
    state,
    { data, hasLiveness }: { data: EmailTemplateOptions; hasLiveness: boolean }
  ) {
    const fields = data?.fields || [];
    const fieldsWithContractTemplates: EmailTemplateOption[] = [];

    for (const field of fields) {
      if (field.name !== CONTRACT_LINK) {
        fieldsWithContractTemplates.push(field);
        continue;
      }

      const contractsStore = useContractBuilderStore();
      const templates = contractsStore.templates?.data || [];
      // [tag] => tag
      const fieldTag = field.tag.slice(1, field.tag.length - 1);

      for (const template of templates) {
        const name = `${CONTRACT_LINK}: ${template.name}%s`;
        const tag = `[${fieldTag}__contract_template_id=${template.id}%s]`;

        const contractTemplateOptions = hasLiveness
          ? [
              {
                name: name.replace("%s", ` - ${t("COMMON.LIVENESS")}`),
                tag: tag.replace("%s", ",idv=1")
              },
              {
                name: name.replace("%s", ""),
                tag: tag.replace("%s", ",idv=0")
              }
            ]
          : [{ name: name.replace("%s", ""), tag: tag.replace("%s", "") }];
        fieldsWithContractTemplates.push(...contractTemplateOptions);
      }
    }

    state.email_templates = { fields: fieldsWithContractTemplates };
  },
  setContractTemplatesOptions(state, data: ContractTemplateOptions) {
    state.contract_templates = data;
  },
  setApplicationOptions(state, data: IOptionsApplication) {
    localStorage.setItem("applicationsOptions", JSON.stringify(data));
    state.application = data;
  },
  setOfferOptions(state, data: OfferOptions) {
    localStorage.setItem("offerTemplatesOptions", JSON.stringify(data));
    state.offerTemplateOptions = data;
  },
  setAttributeOptions(state, data: AttributeOptions) {
    state.all.attributes = data;
  },
  setWorkflowTemplatesOptions(state, data: IOptionsWorkflowTemplates) {
    localStorage.setItem("workflowTemplatesOptions", JSON.stringify(data));
    state.workflow_templates = data;
  },
  setAttributeRelatedOptions(
    state,
    data: { id: string; options: Record<string, string> }
  ) {
    state.attributeOptions = {
      ...state.attributeOptions,
      [data.id]: data.options
    };
  },
  resetState(state) {
    const localStorageKeys = [
      "options",
      "settings",
      "applicationsOptions",
      "workflowTemplatesOptions"
    ] as const;
    const storeKeys = [
      "all",
      "dashboardSettings",
      "application",
      "workflow_templates"
    ] as const;
    localStorageKeys.forEach((key) =>
      localStorage.setItem(key, key === "options" ? "" : JSON.stringify({}))
    );
    storeKeys.forEach((key) => {
      set(state, key, key === "all" ? {} : null);
    });
  },
  setSettings(state, { key, value }: { key: string; value: string | boolean }) {
    const settingsToAppend = { [key]: value };
    const currentSettings = state.dashboardSettings ?? {};

    localStorage.setItem(
      "settings",
      JSON.stringify({
        ...currentSettings,
        ...settingsToAppend
      })
    );

    state.dashboardSettings = loadLocalStorageOptions("settings");
  },
  setExpandedContent(state, newExpandedServiceData: IExpandedServiceData) {
    if (state.all.expandedServiceData) {
      const { expandedServiceData } = state.all;

      let updatedExpandedServiceData: IExpandedServiceData[] = [];
      const foundService = expandedServiceData.find(
        (item) => item.service === newExpandedServiceData.service
      );

      if (foundService) {
        updatedExpandedServiceData = expandedServiceData.map((item) => {
          if (item.service === newExpandedServiceData.service) {
            return {
              ...item,
              ...newExpandedServiceData
            };
          }
          return item;
        });
      } else {
        updatedExpandedServiceData = [
          ...expandedServiceData,
          newExpandedServiceData
        ];
      }

      state.all.expandedServiceData = [...updatedExpandedServiceData];
    } else {
      state.all.expandedServiceData = [newExpandedServiceData];
    }

    localStorage.setItem("options", JSON.stringify(state.all));
  }
};

const actions: ActionTree<IOptionsState, IRootState> = {
  async getAll({ commit }) {
    const data = await optionsApiService.getOptions({
      file_mapping: FILE_MAPPING.dashboard
    });
    commit("setOptions", data);
  },
  async getApplicationOptions({ commit, state }) {
    if (isEmpty(state.application)) {
      const data = await optionsApiService.getApplicationsOptions();
      commit("setApplicationOptions", data);
    }
  },
  async getWorkflowTemplatesOptions({ commit }) {
    if (isEmpty(state.workflow_templates)) {
      const data = await optionsApiService.getWorkflowTemplatesOptions();
      commit("setWorkflowTemplatesOptions", data);
    }
  },
  async getEmailTemplatesOptions({ commit, rootState }) {
    const contractBuilderStore = useContractBuilderStore();
    const client = rootState.auth?.authClientSettings;
    const clients = convertToArray(client?.id);
    await contractBuilderStore.getContractTemplates({
      page: 1,
      per_page: DEFAULT_PER_PAGE,
      status: TEMPLATE_MODES.published,
      clients
    });
    const data = await optionsApiService.getEmailTemplatesOptions();
    commit("setEmailTemplatesOptions", {
      data,
      hasLiveness: !!client?.has_docusign_liveness
    });
  },
  async getAttributeOptions({ commit }) {
    const data = await optionsApiService.getAttributeOptions();
    commit("setAttributeOptions", data);
  },
  async getContractTemplatesOptions({ commit }) {
    if (isEmpty(state.contract_templates)) {
      const data = await optionsApiService.getContractTemplatesOptions();
      commit("setContractTemplatesOptions", data);
    }
  },
  async getOfferTemplateOptions({ commit }) {
    if (isEmpty(state.offerTemplateOptions)) {
      const data = await optionsApiService.getOfferTemplatesOptions();
      commit("setOfferOptions", data);
    }
  },
  async getSingleAttributeOptions({ commit }, id: string) {
    const data = await optionsApiService.getSingleAttributeOptions(id);
    commit("setAttributeRelatedOptions", data);
  },

  async resetOptionsByName({ commit }, name: string) {
    switch (name) {
      case "application":
        commit("setApplicationOptions", {});
        break;
      case "workflow_templates":
        commit("setWorkflowTemplatesOptions", {});
    }
  }
};

const getters: GetterTree<IOptionsState, IRootState> = {
  all(state) {
    return state.all || ({} as IOptions);
  },
  emailTemplates(state) {
    return state.email_templates;
  },
  contractTemplates(state) {
    return state.contract_templates;
  },
  riskTiers(state) {
    return state.all.funder_risk_tiers || [];
  },
  riskTiersMap(state) {
    return state.all.funder_risk_tiers.reduce<Record<RiskTier, string>>(
      (acc: Record<RiskTier, string>, riskTier: RiskTierOption) => {
        acc[riskTier.indicator] = riskTier.name;
        return acc;
      },
      {} as Record<RiskTier, string>
    );
  },
  productTypes(state) {
    return state.all.products || {};
  },
  scorecardProductTypes(state) {
    return state.all.score_cards_products || {};
  },
  callDispositions(state) {
    return state.all.communication?.call_dispositions || [];
  },
  callDispositionMap(state) {
    return state.application?.filters?.last_call_disposition?.data || {};
  },
  latestScoreCardStatuses(state) {
    return (
      state.all.application?.filters?.latest_score_card_statuses.data || {}
    );
  },
  productFields:
    (state) =>
    (id: number | null = null) => {
      const fields =
        id !== null && id !== undefined
          ? state.all.product_fields[id]
          : state.all.product_fields || {};
      return fields;
    },
  productFieldsDictionary:
    (state) =>
    (productType: number | string | null = null) => {
      return productType
        ? state.all.product_fields_dictionary[`${productType}`]
        : state.all.product_fields_dictionary || ({} as Record<string, string>);
    },
  productFundingFields:
    (state) =>
    (id: number | null = null) => {
      const fields = state.all.product_funding_fields || {};
      return id !== null && id !== undefined ? fields[id] : fields || {};
    },
  countries(state) {
    return state.all.countries || {};
  },
  applicationStatuses(state) {
    return state.application?.statuses ?? [];
  },
  phases(state) {
    // TODO it should be replaced with workflow_templates.definition.stages
    const statuses = state.application?.statuses?.reduce<
      Record<string, string>
    >((acc, current) => {
      acc[current.id] = current.status;
      return acc;
    }, {});

    if (!statuses) {
      return {};
    }
    const phases = { ...statuses };
    phases[0] = "Application";

    delete phases[6]; // remove "decline phase"
    return phases;
  },
  states(state) {
    return state.all.states || {};
  },
  stateNames(state) {
    return Object.values(state.all.states || {}).reduce(
      (acc, state) => ({ ...acc, [state]: state }),
      {}
    );
  },
  cities(state) {
    return state.all.cities || {};
  },
  roles(state) {
    return state.all.roles || {};
  },
  deadStatus(state) {
    const statuses = state.application?.statuses?.reduce<
      Record<string, string>
    >((acc, current) => {
      acc[current.id] = current.status;
      return acc;
    }, {});
    if (!statuses) {
      return "";
    }
    return Object.keys(statuses).find(
      (key) => statuses[Number(key)] === "Dead"
    );
  },
  placementDeclineReasons(state) {
    return state.all.placement?.new_decline_reasons || {};
  },
  placementOldDeclineReasons(state) {
    return state.all.placement?.decline_reasons || {};
  },
  placementAllDeclineReasons(_, getters) {
    return {
      ...getters["placementDeclineReasons"],
      ...getters["placementOldDeclineReasons"]
    };
  },
  placementDeclineSubReasons(state) {
    return state.all.placement.decline_subreasons;
  },
  placementDeclineSubReasonsByReasonId:
    (_, getters) => (id: string | number) => {
      const subReasons: IPlacementDeclineSubReason[] =
        getters["placementDeclineSubReasons"];
      return subReasons.filter(
        (subReason) => subReason.parent_id === Number(id)
      );
    },
  placementDeclineSubReasonById: (_, getters) => (id: number | string) => {
    const subReasons: IPlacementDeclineSubReason[] =
      getters["placementDeclineSubReasons"];

    return subReasons.find((subReason) => subReason.id === Number(id));
  },
  monthlyRevenue(state) {
    return state.all.monthly_revenue || {};
  },
  placementStatuses(state) {
    return state.all.placement?.statuses || {};
  },
  offerStatuses(state) {
    return state.all.offers?.statuses || {};
  },
  baseRate(state) {
    return state.all.offers?.base_rate || {};
  },
  interestChargedTimeframe(state) {
    return state.all.offers?.interest_charged || {};
  },
  termLengthTimeframe(state) {
    return state.all.offers?.term_length_timeframe || {};
  },
  timeToFindingTimeframe(state) {
    return state.all.offers?.time_to_funding_timeframe || {};
  },
  contactVia(state) {
    return state.all.offers?.contact_via || ({} as Record<string, string>);
  },
  closingStatuses(state) {
    return state.application?.closing_statuses || {};
  },
  creditScores(state) {
    return state.all.credit_scores || {};
  },
  timeInBusiness(state) {
    return state.all.times_in_businesses || {};
  },
  timeInBusinessForFunders(state) {
    const options = state.all.times_in_businesses || {};
    if (options[HAVENT_REGISTERED_OPTION]) {
      delete options[HAVENT_REGISTERED_OPTION];
    }
    return options;
  },
  timeZones(state) {
    return {
      ["null"]: t("COMMON.NONE"),
      ...(state.all.time_zones || {})
    };
  },
  paymentPeriods:
    (state) =>
    (id: number | null = null) => {
      return id
        ? TERM_LENGTH_OPTIONS[
            state.all.offers?.payment_periods[id] as TermLengthOption
          ]
        : state.all.offers.payment_periods || {};
    },
  industryTypes: (state) => () => {
    return state.all.industry_types || [];
  },
  industryType: (state) => (id: number) => {
    return id
      ? (state.all.industry_types || []).filter(
          (industry) => industry.id === id
        )[0] || {}
      : {};
  },

  subIndustryTypes:
    (state) =>
    (industryId?: number): ISubIndustry[] | SubIndustryWithParent[] => {
      if (industryId) {
        return (
          state.all.industry_types?.filter(
            (industry: IIndustry) => industry.id === industryId
          )?.[0]?.sub_industry_types || []
        );
      }
      return state.all.industry_types?.flatMap((industry) =>
        industry.sub_industry_types.map((subIndustry) => ({
          parent_id: industry.id,
          ...subIndustry
        }))
      );
    },
  subIndustryType: (state) => (industryId: number, subIndustryId: number) => {
    return (
      state.all.industry_types
        .filter((industry: IIndustry) => industry.id === industryId)?.[0]
        ?.sub_industry_types.filter(
          (subIndustry: ISubIndustry) => subIndustry.id === subIndustryId
        )[0] || ({} as ISubIndustry)
    );
  },
  naicsCodes: (state) => {
    return state.application?.naics_codes;
  },
  businessEntityTypes:
    (state) =>
    (id: number | null = null) => {
      return id
        ? state.all.business_entity_types[id]
        : state.all.business_entity_types || ({} as Record<string, string>);
    },
  numberOfEmployees:
    (state) =>
    (id: number | null = null) => {
      return id
        ? state.all.number_of_employees.find((item) => item.id == id)?.label
        : state.all.number_of_employees || [];
    },
  deadReasons:
    (state) =>
    (id: number | null = null) => {
      const reasons = state.application?.dead_reasons || {};
      if (id) {
        return reasons[id] ?? "";
      }
      return reasons;
    },
  deadSubReasons:
    (state) =>
    (id: number | null = null) => {
      const subReasons = state.application?.dead_sub_reasons ?? {};
      if (id) {
        return subReasons[id] ?? "";
      }
      return subReasons;
    },
  useOfFunds:
    (state) =>
    (id: number | null = null) => {
      return id ? state.all.use_of_funds[id] : state.all.use_of_funds || {};
    },
  fundsNeededTimes: (state) => {
    return state.all.funds_needed_times;
  },
  notificationTypes(state) {
    return state.all.notification_types;
  },
  fileTypes(state) {
    return state.all.file_types;
  },
  discoveryCallQuestions(state) {
    return state.all.discovery_call_questions;
  },
  businessCreditServices(state) {
    return state.all.business_credit_services;
  },
  experianBopBusinessCreditServices(state) {
    return {
      ...state.all.business_credit_services[EXPERIAN_BUSINESS_OWNER_PROFILE],
      ...state.all.business_credit_services[
        EXPERIAN_BUSINESS_OWNER_PROFILE_PREMIER
      ]
    };
  },
  experianBopConsumerScoreModels(state) {
    return state.all.experian_bop_consumer_score_models;
  },
  transUnionModels(state) {
    return state.all.transunion_models;
  },
  settings(state) {
    return state.dashboardSettings ?? {};
  },
  funderContactRoles(state) {
    return state.all.funder_contact_roles;
  },
  placementMethods(state) {
    return state.all.placement?.methods || {};
  },
  equipmentRentalApprovals(state) {
    return state.application?.eq_rental_approvals ?? "";
  },
  outcomes(state) {
    return state.application?.outcomes ?? {};
  },
  citizenship(state) {
    return state.all.citizenship;
  },
  languages(state) {
    return state.all.languages;
  },
  sources(state) {
    return state.application?.sources ?? {};
  },
  userStatuses(state) {
    return state.all.user?.status;
  },
  dealModes(state) {
    return state.application?.deal_modes ?? {};
  },
  dataOrchestrationStatuses(state) {
    return state.all.data_orchestration?.statuses || {};
  },
  dataOrchestrationMasterStatuses(state) {
    return state.all.data_orchestration?.master_statuses || {};
  },
  dataOrchestrationTriggers(state) {
    return state.all.data_orchestration?.triggers || {};
  },
  expandedServiceData(state) {
    return state.all.expandedServiceData;
  },
  workflowTemplatesWidgets(state) {
    return state.workflow_templates?.widgets ?? {};
  },
  workflowTemplatesDefinition(state) {
    return state.workflow_templates?.definition ?? {};
  },
  defaultDiscoveryCallQuestions(state) {
    return state.all.discovery_call_default_questions;
  },
  workflowStagesInfo(state) {
    return state.workflow_templates?.definition?.stages ?? [];
  },
  borrowerPlatformDefinition(state) {
    return state.workflow_templates?.definition.borrower_platform;
  },
  businessRelationships(state) {
    return state.all.profile_management?.relationships || [];
  },
  revenueRanges(state) {
    return Object.fromEntries(
      Object.entries(state.all.monthly_revenue)
        .sort((a, b) =>
          Number(a[0].split("_")[3]) > Number(b[0].split("_")[3]) ? 1 : -1
        )
        .map((entry) => {
          if (!entry[1].includes("-")) {
            return [entry[0], `${formatMoney(entry[1].replace(/\D/, ""))}+`];
          }
          return [entry[0], formatMoneyRange(entry[1])];
        })
    );
  },
  smartviewNotificationTypes(state) {
    return state.application?.filter_notification_types || [];
  },
  attributeStatuses(state) {
    return state.all.attribute?.statuses || [];
  },
  attributeFunctions(state) {
    return state.all?.attributes?.functions ?? [];
  },
  insuranceTypes(state) {
    return state.all.insurance_types;
  },
  piiMapping(state) {
    return state.all.pii_mapping;
  },
  loanServicers(state) {
    return state.all.loan_servicers;
  },
  workflowCategoriesInfo(state) {
    return state.workflow_templates?.categories ?? [];
  },
  debtTypes(state) {
    return state.all.debt_types;
  },
  workflowWebhooks(state) {
    return state.workflow_templates?.definition?.webhooks ?? [];
  },
  inverseRelationshipsMap(state) {
    return Object.values(state.all.profile_management?.relationships).reduce(
      (inverseMap: Record<string, string>, relationship) => {
        inverseMap[relationship.type.id] = relationship.inverse.id;
        inverseMap[relationship.inverse.id] = relationship.type.id;
        return inverseMap;
      },
      {}
    );
  },
  taskPriorityLevels(state) {
    return state.all.task.priority_levels;
  },
  stageCategories(state) {
    return state.application?.filters?.stage_categories?.data ?? {};
  },
  attributeSchemaVersion(state) {
    return (
      state.all?.attributes?.latest_schema_version ??
      CUSTOM_ATTRIBUTE_V2_SCHEMA_VERSION
    );
  },
  workflowBillingTypes(state) {
    return state.workflow_templates?.billing_types ?? [];
  },
  workflowSegments(state) {
    return state.all.workflow_templates?.workflow_segments ?? [];
  },
  servicesAllowedToEditJson(state) {
    return state.all.services_allowed_for_updating_enrichment_response;
  },
  attributeOptions(state) {
    return state.attributeOptions;
  },
  automationOutcomes(state) {
    return state.all.workflow_orchestration_log_statuses;
  },
  significantOfferFields() {
    const allFields = Object.values(
      state.offerTemplateOptions?.meaningful_fields || {}
    ).reduce((acc: OfferTemplateField[], fields) => {
      acc.push(fields as unknown as OfferTemplateField);
      return acc;
    }, []);

    return allFields
      .flat()
      .reduce((acc: Record<string, OfferTemplateField>, field) => {
        acc[field.name] = field;
        return acc;
      }, {});
  },
  offerFieldTypes() {
    return state.offerTemplateOptions?.field_types;
  },
  offerDefaultTemplates() {
    return state.offerTemplateOptions?.fallback_templates;
  },
  offerOptions(state) {
    return state.offerTemplateOptions;
  },
  fallBackOfferTemplatesOptions(state) {
    return state.offerTemplateOptions?.fallback_templates.templates || [];
  }
};

export const options = {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};
