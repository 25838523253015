<template>
  <div class="flex-col space-y-3">
    <div class="flex flex-col py-3 space-y-6">
      <div v-if="details" class="flex flex-col space-y-6">
        <search-input
          v-model="searchPhrase"
          data-cy="dsd-search-input"
          class="w-full h-10"
          no-padding
          no-margin
          :delay="100"
          :disabled="isActiveTemplatePublished"
        />
        <div class="px-1 space-y-3">
          <lf-checkbox
            v-if="canSelectMultiple"
            v-model="areAllDsdsSelected"
            data-cy="dsd-option-select-all"
            name="dsdSelectAll"
            value=""
            :disabled="isActiveTemplatePublished"
          >
            <span class="text-gray-400">
              {{ $t("COMMON.SELECT_ALL") }}
            </span>
          </lf-checkbox>
          <service-detail-item
            v-for="(value, key) in Object.keys(details)"
            :key="key"
            :active-dsds="activeDsds"
            class="flex-col bg-white rounded"
            :class="{
              'cursor-not-allowed pointer-events-none opacity-50':
                isActiveTemplatePublished
            }"
            :is-multiple="canSelectMultiple"
            :disabled="isActiveTemplatePublished"
            :index="String(key)"
            :detail-name="value"
            :needle="searchPhrase"
            :attributes="details?.[value] || []"
            :selected-attributes-ids="selectedAttributesIds"
            @update:selected-attributes="handleSave(value, $event)"
            @update:active-dsds="setActiveDetails"
          >
            <template #additional-content>
              <div class="p-5 pb-0 space-y-4">
                <div v-if="repullOptionEnabled" class="flex flex-col space-y-4">
                  <div class="flex space-x-3 space-y-1">
                    <lf-checkbox
                      v-model="repullOptionSelected"
                      name="re_pull_option"
                      :value="repullOptionSelected"
                      :disabled="isActiveTemplatePublished"
                    >
                      {{ $t("ORCHESTRATION.REPULL_DATA_MESSAGE") }}
                    </lf-checkbox>
                    <icon-base
                      v-tooltip="$t('ORCHESTRATION.REPULL_DATA_TOOLTIP')"
                      icon="info-small"
                      :icon-color="UTIL_COLORS.DEFAULT"
                      class="cursor-point"
                    />
                  </div>
                  <div class="flex space-x-3">
                    <lf-input
                      class="h-10"
                      name="refresh_ttl"
                      type="number"
                      :min="0"
                      :value="refreshTtl ?? undefined"
                      :disabled="isActiveTemplatePublished"
                      no-margin
                      @key-released="handleRefreshTtlValueChanged"
                    />
                    <lf-dropdown
                      class="min-w-50 mr-4 max-h-10"
                      name="refresh_ttl_period"
                      :options="DO_TEMPLATE_REFRESH_TTL_PERIODS"
                      :value="DO_TEMPLATE_REFRESH_TTL_PERIODS[refreshTtlPeriod]"
                      :disabled="isActiveTemplatePublished"
                      full-width
                      @change="handleRefreshTtlPeriodChanged"
                    />
                  </div>
                </div>
                <div v-if="ocrolusSourceEnabled">
                  <lf-dropdown
                    class="min-w-50 max-h-10"
                    name="ocrolus_book_source"
                    :placeholder="$t('ORCHESTRATION.OCROLUS_BOOK_SOURCE')"
                    :options="OCROLUS_BOOK_OPTIONS"
                    :value="ocrolusBookOption"
                    full-width
                    @change="handleChangeBookSource"
                  />
                </div>
                <div v-if="inscribeSourceEnabled">
                  <lf-dropdown
                    class="min-w-50 h-10"
                    name="inscribe_number_of_bank_statements"
                    :placeholder="$t('ORCHESTRATION.NUMBER_OF_BANK_STATEMENTS')"
                    :options="inscribeBankStatementOptions"
                    :value="inscribeBankStatementOption"
                    full-width
                    @change="handleChangeBankStatementOption"
                  />
                </div>
                <div v-if="canIncludeInsights">
                  <lf-checkbox
                    v-model="includeInsights"
                    name="include_insights"
                    :value="includeInsights"
                    :disabled="isActiveTemplatePublished"
                    @change="updateIncludeInsightsValue"
                  >
                    {{
                      $t("DEALS.DEAL_PROGRESS.BANK_CONNECTION.INCLUDE_INSIGHTS")
                    }}
                  </lf-checkbox>
                </div>
                <div v-if="canSyncTransactions">
                  <lf-checkbox
                    v-model="syncTransactions"
                    name="track_transaction_updates"
                    :value="syncTransactions"
                    :disabled="isActiveTemplatePublished"
                    @change="updateSyncTransactionsValue"
                  >
                    {{ $t("DEALS.DEAL_PROGRESS.BANK_CONNECTION.SYNCED_DATA") }}
                  </lf-checkbox>
                </div>
                <div v-if="isExperianQuickSearch">
                  <lf-dropdown
                    name="select_service_type"
                    data-cy="experian-quick-search-source-selector"
                    :custom-button-classnames="{ heightClassname: 'h-10' }"
                    :value="selectedQuickSearchOption"
                    :options="searchOptions"
                    full-width
                    @change="handleChangeQuickSearchOption"
                  />
                </div>
                <request-options
                  v-if="isLexisNexisEmailSearch"
                  v-model="emailSearchSelectedOptions"
                  class="flex-col"
                  @options:update="updateLexisNexisEmailSearchOption"
                />
              </div>
            </template>
          </service-detail-item>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import type { PropType } from "vue";
import { ref, computed, watch } from "vue";
import { useStore } from "vuex";
import type {
  DataOrchestrationTemplateRePullPeriod,
  IAttribute,
  IStep,
  IStepAttribute
} from "@/models/orchestration";
import { useDoActiveTemplateStatus } from "@/hooks/orchestration";

import {
  DISABLED_SERVICES_FOR_DATA_RE_PULL,
  DO_TEMPLATE_REFRESH_TTL_PERIODS,
  DO_TEMPLATE_REFRESH_TTL_CONVERSIONS_FROM_HOURS,
  UTIL_COLORS,
  MONTHS
} from "@/helpers/constants";
import { OCROLUS_BOOK_OPTIONS } from "@/helpers/constants/ocrolus";
import { OCROLUS_BOOK_OPTION_BANK_STATEMENTS_INSTANT } from "@/helpers/constants/ocrolus";
import LfDropdown from "@/components/ui/inputs/LfDropdown.vue";
import LfCheckbox from "@/components/ui/inputs/LfCheckbox.vue";
import LfInput from "@/components/ui/inputs/LfInput.vue";
import IconBase from "@/components/ui/IconBase.vue";
import SearchInput from "@/components/ui/inputs/SearchInput.vue";
import ServiceDetailItem from "@/views/profile/components/dataOrchestration/configPanel/ServiceDetailItem.vue";
import RequestOptions from "@/components/deals/dataServices/kyc/lexisNexis/emailSearch/RequestOptions.vue";
import cloneDeep from "lodash/cloneDeep";
import zipObject from "lodash/zipObject";
import map from "lodash/map";
import { getObjectKeys, looksLikeNumber } from "@/helpers/common";
import { searchOptions } from "@/helpers/constants/experian";
import { SearchOption } from "@/enums/experian";
import { OcrolusBookClass } from "@/enums/dataServices";
import type { SelectedOptions } from "@/models/commercialData/lexisnexis/LexisNexisEmailSearchRequest";
import isEqual from "lodash/isEqual";

const props = defineProps({
  serviceName: {
    type: String,
    default: ""
  },
  details: {
    type: Object as PropType<Record<string, IAttribute[]>>
  },
  activeStep: {
    type: Object as PropType<IStep | null>,
    required: true
  }
});

const { commit, getters } = useStore();
const { isActiveTemplatePublished } = useDoActiveTemplateStatus();

const PLAID_TRANSACTIONS = "Plaid Transactions" as const;
const PLAID_ASSET_REPORT = "Plaid Asset Report" as const;
const OCROLUS = "Ocrolus" as const;
const INSCRIBE = "Inscribe" as const;
const defaultInscribeBankStatementOption = 3 as const;
const lexisNexisServiceName = "Lexis Nexis" as const;
const lexisNexisEmailSearchType = "Email Search" as const;
const experianServiceName = "Experian" as const;
const experianQuickSearchType = "Quick Search" as const;

const includeInsights = ref(false);
const syncTransactions = ref(false);

const selectedQuickSearchOption = ref<SearchOption>(SearchOption.BUSINESS_NAME);

const emailSearchSelectedOptions = ref<SelectedOptions>({
  search_tier: "Basic",
  search_type: "EAA",
  is_marketing_use: 1
});

const selectedAttributes = computed(() => props.activeStep?.attributes || []);
const selectedAttributesIds = computed(() =>
  selectedAttributes.value.map((attr) => attr.id)
);
const canSelectMultiple = computed(() =>
  props.serviceName.toLowerCase().includes("transunion")
);

const isExperianQuickSearch = computed(
  () =>
    props.serviceName === experianServiceName &&
    !canSelectMultiple.value &&
    activeDsds.value[0] === experianQuickSearchType
);

const isLexisNexisEmailSearch = computed(
  () =>
    props.serviceName === lexisNexisServiceName &&
    !canSelectMultiple.value &&
    activeDsds.value[0] === lexisNexisEmailSearchType
);

const canIncludeInsights = computed(
  () => !canSelectMultiple.value && activeDsds.value[0] === PLAID_ASSET_REPORT
);

const canSyncTransactions = computed(
  () => !canSelectMultiple.value && activeDsds.value[0] === PLAID_TRANSACTIONS
);

const repullOptionEnabled = computed(
  () => !DISABLED_SERVICES_FOR_DATA_RE_PULL.includes(props.serviceName)
);

const ocrolusSourceEnabled = computed(() => {
  return props.serviceName === OCROLUS;
});

const inscribeSourceEnabled = computed(() => {
  return props.serviceName === INSCRIBE;
});

const getSelectedModels = (singleModel = true): string[] => {
  const keys = Object.keys(props.details || {});

  if (!keys.length) {
    return [];
  }

  return singleModel
    ? [keys.find(findModelInAttributes) ?? ""]
    : keys.filter(findModelInAttributes) || [];
};

const findModelInAttributes = (key: string) =>
  selectedAttributes.value.some(
    (attr) => props.details?.[key]?.map((a) => a.id).includes(attr.id)
  );

// DSDs - data services details
const activeDsds = ref<string[]>(
  canSelectMultiple.value ? getSelectedModels(false) : [getSelectedModels()[0]]
);

const refreshTtlPeriod = ref<DataOrchestrationTemplateRePullPeriod>(
  Object.keys(
    DO_TEMPLATE_REFRESH_TTL_PERIODS
  )[0] as DataOrchestrationTemplateRePullPeriod
);

const inscribeBankStatementOption = ref<number>(
  defaultInscribeBankStatementOption
);
const inscribeBankStatementOptions = ref(
  zipObject(map(MONTHS, "monthNum"), map(MONTHS, "monthNum"))
);

const ocrolusBookOption = ref<keyof typeof OCROLUS_BOOK_OPTIONS>(
  OCROLUS_BOOK_OPTION_BANK_STATEMENTS_INSTANT
);
const ocrolusBookClass = ref<OcrolusBookClass>(OcrolusBookClass.COMPLETE);

const searchPhrase = ref("");

const extractRefreshTtl = () => {
  if (selectedAttributes.value.length) {
    return selectedAttributes.value[0].options?.refresh_ttl_hours;
  }

  // If there are no attributes for active step yet,
  // try to find the step with the same title within the template
  // and extract refresh TTL from there
  const steps = getters["orchestration/activeTemplateSteps"] as IStep[];
  const sameStepAsActiveOne = steps.find(
    (step) =>
      step.id !== props.activeStep?.id && step.title === props.activeStep?.title
  );

  return (
    sameStepAsActiveOne?.attributes?.[0]?.options?.refresh_ttl_hours ?? null
  );
};

const refreshTtl = ref(extractRefreshTtl());

const repullOptionSelected = ref(refreshTtl.value != null);

const areAllDsdsSelected = computed({
  get() {
    const all = getObjectKeys(props.details ?? {});
    return !!all.length && all.every((dsd) => activeDsds.value.includes(dsd));
  },
  set(value) {
    activeDsds.value = value ? Object.keys(props.details || {}) : [];
  }
});

const attributesActions = computed(() => [
  { condition: ocrolusSourceEnabled.value, execute: updateOcrolusBookSource },
  { condition: canIncludeInsights.value, execute: updateIncludeInsightsValue },
  {
    condition: canSyncTransactions.value,
    execute: updateSyncTransactionsValue
  },
  {
    condition: inscribeSourceEnabled.value,
    execute: updateInscribeBankStatementsValue
  },
  { condition: isExperianQuickSearch.value, execute: updateQuickSearchOption },
  {
    condition: isLexisNexisEmailSearch.value,
    execute: updateLexisNexisEmailSearchOption
  }
]);

const onAfterStepAttributesUpdate = () => {
  updateRefreshTtl();

  const requiredAction = attributesActions.value.find(
    ({ condition }) => condition
  );

  requiredAction?.execute();
};

const handleSave = (detail: string, rawAttributes: IAttribute["id"][] = []) => {
  const shouldUpdateDsds = canSelectMultiple.value
    ? !activeDsds.value.includes(detail)
    : activeDsds.value[0] !== detail;

  if (shouldUpdateDsds) {
    const newDsds = canSelectMultiple.value
      ? Array.from(new Set([...activeDsds.value, detail]))
      : [detail];
    setActiveDetails(newDsds);
  }
  let attributes = rawAttributes;

  if (!canSelectMultiple.value && shouldUpdateDsds) {
    attributes = attributes.filter(
      (attribute) =>
        props.details?.[detail]?.map((a) => a.id).includes(attribute)
    );
  }

  const removedAttributes = selectedAttributes.value?.filter(
    (attribute) => !attributes.includes(attribute.id)
  );
  commit("orchestration/setStepAttributes", {
    stepId: props.activeStep?.id,
    attributes: attributes.map((id) => ({ id }))
  });

  onAfterStepAttributesUpdate();

  if (removedAttributes.length) {
    commit("orchestration/removeAttributesFromOutcomes", {
      stepId: props.activeStep?.id,
      removedAttributes
    });
  }
};

const setActiveDetails = (newDsds: string[]) => {
  activeDsds.value = newDsds;

  if (canSelectMultiple.value) {
    const attributesToRetain = newDsds.flatMap((dsd) =>
      selectedAttributes.value.reduce<string[]>((acc, attribute) => {
        if (props.details?.[dsd]?.map((a) => a.id).includes(attribute.id)) {
          acc.push(attribute.id);
        }
        return acc;
      }, [])
    );
    commit("orchestration/setStepAttributes", {
      stepId: props.activeStep?.id,
      attributes: attributesToRetain.map((id) => ({ id }))
    });
    onAfterStepAttributesUpdate();
  } else {
    if (selectedAttributes.value.length) {
      commit("orchestration/removeAttributesFromOutcomes", {
        stepId: props.activeStep?.id,
        removedAttributes: selectedAttributes.value
      });
    }
    commit("orchestration/setStepAttributes", {
      stepId: props.activeStep?.id,
      attributes: []
    });
  }
};

const handleRefreshTtlValueChanged = (value: number) => {
  refreshTtl.value = value;
  updateRefreshTtl();
};

const handleRefreshTtlPeriodChanged = (period: string | number) => {
  refreshTtlPeriod.value = String(
    period
  ) as DataOrchestrationTemplateRePullPeriod;
  updateRefreshTtl();
};

const handleChangeBookSource = (option: string | number) => {
  ocrolusBookOption.value = String(option) as keyof typeof OCROLUS_BOOK_OPTIONS;
  updateOcrolusBookSource();
};

const handleChangeQuickSearchOption = (option: string | number) => {
  selectedQuickSearchOption.value = String(
    option
  ) as keyof typeof searchOptions;
  updateQuickSearchOption();
};

const handleChangeBankStatementOption = (option: string | number) => {
  inscribeBankStatementOption.value = Number(option);
  updateInscribeBankStatementsValue();
};

const updateInscribeBankStatementsValue = () => {
  const attributes = cloneDeep(selectedAttributes.value);

  attributes.forEach((attribute) => {
    const numberOfBankStatements = looksLikeNumber(
      inscribeBankStatementOption.value
    )
      ? Number(inscribeBankStatementOption.value)
      : defaultInscribeBankStatementOption;
    attribute.options = {
      number_of_bank_statements: numberOfBankStatements
    };
  });

  commit("orchestration/setStepAttributes", {
    stepId: props.activeStep?.id,
    attributes
  });
};

const updateRefreshTtl = () => {
  const attributes = cloneDeep(selectedAttributes.value);

  const convertedRefreshTtl =
    refreshTtl.value != null && repullOptionSelected.value
      ? refreshTtl.value *
        DO_TEMPLATE_REFRESH_TTL_CONVERSIONS_FROM_HOURS[refreshTtlPeriod.value]
      : null;

  attributes.forEach((attribute) => {
    if (convertedRefreshTtl == null) {
      if (!attribute.options) {
        return;
      }

      delete attribute.options;
      return;
    }

    attribute.options = {
      refresh_ttl_hours: convertedRefreshTtl
    };
  });

  commit("orchestration/setStepAttributes", {
    stepId: props.activeStep?.id,
    attributes
  });
};

const getSavedOcrolusBookSource = (attributes: IStepAttribute[]) => {
  if (!attributes.length) {
    return;
  }
  const source = attributes[0].options?.ocrolus_book_source;
  const savedClass = attributes[0].options?.ocrolus_book_class;
  if (source) {
    if (savedClass === OcrolusBookClass.INSTANT) {
      ocrolusBookOption.value =
        `${source}_instant` as keyof typeof OCROLUS_BOOK_OPTIONS;
    } else {
      ocrolusBookOption.value = source as keyof typeof OCROLUS_BOOK_OPTIONS;
    }
  }
  if (savedClass) {
    ocrolusBookClass.value = savedClass as OcrolusBookClass;
  }
};

const getIncludeInsightsValue = (attributes: IStepAttribute[]) => {
  const attributeIncludeInsightsContextValue =
    attributes[0]?.options?.include_insights;

  if (attributeIncludeInsightsContextValue === undefined) {
    return;
  }

  includeInsights.value = attributeIncludeInsightsContextValue;
};

const getSyncTransactionsValue = (attributes: IStepAttribute[]) => {
  const attributeSyncTransactionsContextValue =
    attributes[0]?.options?.track_transaction_updates;

  if (attributeSyncTransactionsContextValue === undefined) {
    return;
  }

  syncTransactions.value = attributeSyncTransactionsContextValue;
};

const getExperianQuickSearchSource = (attributes: IStepAttribute[]) => {
  selectedQuickSearchOption.value =
    attributes[0]?.options?.quick_search_source ||
    selectedQuickSearchOption.value ||
    SearchOption.BUSINESS_NAME;
};

const getLexisNexisEmailSearchOptions = (attributes: IStepAttribute[]) => {
  const attributeOptions = attributes[0]?.options;

  if (!attributeOptions) {
    return;
  }

  const { search_tier, search_type, is_marketing_use } =
    emailSearchSelectedOptions.value;

  const emailSearchAttributeOptions: SelectedOptions = {
    search_tier: attributeOptions.search_tier || search_tier,
    search_type: attributeOptions.search_type || search_type,
    is_marketing_use: attributeOptions.is_marketing_use || is_marketing_use
  };

  if (!isEqual(emailSearchAttributeOptions, emailSearchSelectedOptions.value)) {
    emailSearchSelectedOptions.value = emailSearchAttributeOptions;
  }
};

const getBankStatementOptionValue = (attributes: IStepAttribute[]) => {
  inscribeBankStatementOption.value =
    attributes[0]?.options?.number_of_bank_statements ||
    defaultInscribeBankStatementOption;
};

const updateOcrolusBookSource = () => {
  const attributes = cloneDeep(selectedAttributes.value);

  attributes.forEach((attribute) => {
    if (ocrolusBookOption.value === "") {
      if (!attribute.options) {
        return;
      }

      delete attribute.options;
      return;
    }
    if (ocrolusBookOption.value.includes(OcrolusBookClass.INSTANT)) {
      attribute.options = {
        ocrolus_book_source: ocrolusBookOption.value.replace("_instant", ""),
        ocrolus_book_class: OcrolusBookClass.INSTANT
      };
      if (ocrolusBookOption.value === "both_instant") {
        delete attribute.options.ocrolus_book_source;
      }
    } else {
      attribute.options = {
        ocrolus_book_source: ocrolusBookOption.value,
        ocrolus_book_class: OcrolusBookClass.COMPLETE
      };
    }
  });

  commit("orchestration/setStepAttributes", {
    stepId: props.activeStep?.id,
    attributes
  });
};

const updateQuickSearchOption = () => {
  const attributes = cloneDeep(selectedAttributes.value);

  attributes.forEach((attribute) => {
    attribute.options = {
      quick_search_source: selectedQuickSearchOption.value
    };
  });

  commit("orchestration/setStepAttributes", {
    stepId: props.activeStep?.id,
    attributes
  });
};

const updateLexisNexisEmailSearchOption = () => {
  const attributes = cloneDeep(selectedAttributes.value);

  attributes.forEach((attribute) => {
    attribute.options = {
      ...(attribute.options || {}),
      ...emailSearchSelectedOptions.value
    };
  });

  commit("orchestration/setStepAttributes", {
    stepId: props.activeStep?.id,
    attributes
  });
};

const updateIncludeInsightsValue = () => {
  const attributes = cloneDeep(selectedAttributes.value);

  attributes.forEach((attribute) => {
    attribute.options = { include_insights: includeInsights.value };
  });

  commit("orchestration/setStepAttributes", {
    stepId: props.activeStep?.id,
    attributes
  });
};

const updateSyncTransactionsValue = () => {
  const attributes = cloneDeep(selectedAttributes.value).map((attribute) => ({
    ...attribute,
    options: { track_transaction_updates: syncTransactions.value }
  }));

  commit("orchestration/setStepAttributes", {
    stepId: props.activeStep?.id,
    attributes
  });
};

watch(repullOptionSelected, updateRefreshTtl);

watch(emailSearchSelectedOptions, updateLexisNexisEmailSearchOption, {
  deep: true
});

watch(
  selectedAttributes,
  (attributes) => {
    getSavedOcrolusBookSource(attributes);
    getIncludeInsightsValue(attributes);
    getSyncTransactionsValue(attributes);
    getBankStatementOptionValue(attributes);
    getExperianQuickSearchSource(attributes);
    getLexisNexisEmailSearchOptions(attributes);
  },
  { immediate: true }
);
</script>
