import type {
  AttributeOptions,
  IOptions,
  EmailTemplateOptions,
  IOptionsApplication,
  IOptionsWorkflowTemplates,
  ContractTemplateOptions
} from "@/models/options";
import { axiosClient } from "../client";
import endpoints from "../endpoints";
import { formatString } from "@/helpers/formatting";

class OptionsApiService {
  async getAttributeOptions() {
    const response = await axiosClient.get<{ data: AttributeOptions }>(
      endpoints.GET_OPTIONS.ATTRIBUTE
    );
    return response.data.data;
  }
  async getOptions(params: Record<string, string | number>) {
    const response = await axiosClient.get<{ data: IOptions }>(
      endpoints.GET_OPTIONS.ALL,
      { params }
    );
    return response.data.data;
  }
  async getEmailTemplatesOptions() {
    const response = await axiosClient.get<{ data: EmailTemplateOptions }>(
      endpoints.GET_OPTIONS.EMAIL_TEMPLATES
    );
    return response.data.data;
  }
  async getApplicationsOptions() {
    const response = await axiosClient.get<{ data: IOptionsApplication }>(
      endpoints.GET_OPTIONS.APPLICATION
    );
    return response.data.data;
  }
  async getWorkflowTemplatesOptions() {
    const response = await axiosClient.get<{ data: IOptionsWorkflowTemplates }>(
      endpoints.GET_OPTIONS.WORKFLOW_TEMPLATES
    );
    return response.data.data;
  }
  async getContractTemplatesOptions() {
    const response = await axiosClient.get<{ data: ContractTemplateOptions }>(
      endpoints.GET_OPTIONS.CONTRACT_TEMPLATES
    );
    return response.data.data;
  }
  async getSingleAttributeOptions(id: string) {
    const response = await axiosClient.get<{
      data: { id: string; options: Record<string, string> };
    }>(formatString(endpoints.GET_OPTIONS.SINGLE_ATTRIBUTE_OPTIONS, id));
    return response.data.data;
  }
  async getOfferTemplatesOptions() {
    const response = await axiosClient.get<{ data: Record<string, unknown> }>(
      endpoints.GET_OPTIONS.OFFER
    );
    return response.data.data;
  }
}

const optionsApiService = new OptionsApiService();
export default optionsApiService;
