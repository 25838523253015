<template>
  <teleport to="#modals">
    <vue-resizable
      v-slot="{ style }"
      class="z-[100]"
      drag-selector=".cursor-grab"
      v-bind="activeModalLocalStorageOptions"
      :min-width="activeModalWidthAndHeight.minWidth"
      :min-height="activeModalWidthAndHeight.minHeight"
      @mount="updatePosition"
      @resize:end="updatePosition"
      @drag:end="updatePosition"
    >
      <div
        ref="resizableElement"
        :style="style"
        class="bg-white rounded-md shadow-lg"
        :class="[isMinimized ? 'max-w-55' : 'h-full', modalClass]"
        data-cy="communication-dialog"
      >
        <div
          class="bg-grey-navigation text-white p-4 rounded-t-md flex justify-between space-x-2"
          :class="{ 'rounded-b-md max-w-55': isMinimized }"
          data-cy="communication-dialog-header"
        >
          <div
            class="flex flex-grow items-center space-x-4 min-w-0"
            data-cy="handle"
          >
            <icon-base
              :icon="IconCardView"
              width="16"
              height="16"
              view-box="0 0 24 24"
              class="transform rotate-90 cursor-grab handle min-w-4"
            />
            <span
              class="font-semibold grow text-white cursor-grab text-ellipsis whitespace-nowrap overflow-hidden"
              @dblclick="toggleMinimize"
            >
              {{ modalType }}{{ dealName ? ` - ${dealName}` : "" }}
            </span>
          </div>
          <div class="flex space-x-2">
            <icon-base
              v-if="isMinimized"
              :icon="IconWidget"
              view-box="0 0 16 14"
              icon-name="Restore"
              :title="$t('COMMON.RESTORE')"
              class="text-white cursor-pointer"
              data-cy="maximize-communication-dialog"
              @click="toggleMinimize"
            />
            <icon-base
              v-else
              :icon="IconSubtract"
              view-box="0 -5 20 20"
              icon-name="Minimize"
              :title="$t('COMMON.MINIMIZE')"
              class="text-white cursor-pointer"
              data-cy="minimize-communication-dialog"
              @click="toggleMinimize"
            />
            <icon-base
              icon="x"
              icon-name="Close"
              class="cursor-pointer text-white"
              @click="emit('close-dialog')"
            />
          </div>
        </div>
        <loader :is-loading="loading" />
        <slot v-if="type === CommunicationType.email" name="email-form">
          <!-- to prevent dynamic fields from not being popluated-->
          <email-form
            v-if="!loading"
            v-show="!isMinimized"
            @close-dialog="emit('close-dialog')"
          />
        </slot>
        <sms-form
          v-else-if="type === CommunicationType.sms && !loading"
          v-show="!isMinimized"
          @close-dialog="emit('close-dialog')"
        />
      </div>
    </vue-resizable>
  </teleport>
</template>

<script setup lang="ts">
import { ref, computed, onMounted } from "vue";
import type { EmailTemplateOptions } from "@/models/options";
import { useStore } from "vuex";
import { useLocalStorageSetting } from "@/hooks/options";
import useEmailsStore from "@/stores/emails";
import IconSubtract from "@/components/icons/IconSubtract.vue";
import IconWidget from "@/components/icons/IconWidget.vue";
import IconCardView from "@/components/icons/IconCardView.vue";
import EmailForm from "@/components/emailSending/EmailForm.vue";
import SmsForm from "@/components/communicationLogs/communication/sms/SmsForm.vue";
import VueResizable from "vue-resizable";
import type { ResizableEventValues } from "@/models/common";
import isEmpty from "lodash/isEmpty";
import {
  DEFAULT_EMAIL_MODAL_OPTIONS,
  DEFAULT_SMS_MODAL_OPTIONS
} from "@/helpers/constants/deals";
import { convertToArray } from "@/helpers/common";
import { usePromiseWrapper } from "@/hooks/common";
import { useRoute } from "vue-router";
import type { CommunicationModalType } from "@/models/communicationLogs";
import { useI18n } from "vue-i18n";
import useSmsStore from "@/stores/sms";
import { CommunicationType } from "@/enums/communicationLogs";

const emit = defineEmits<{
  "close-dialog": [];
}>();

const props = withDefaults(
  defineProps<{
    initialValues: { x: number; y: number };
    dealId: string | string[];
    anchorBottomLeft?: boolean;
    dealName?: string | undefined;
    type?: CommunicationModalType;
  }>(),
  {
    initialValues: () => ({ x: 0, y: 0 }),
    dealId: "",
    anchorBottomLeft: false,
    dealName: "",
    type: CommunicationType.email
  }
);

const { getters, dispatch } = useStore();
const emailStore = useEmailsStore();
const smsStore = useSmsStore();
const route = useRoute();
const { t } = useI18n();

const emailOptions = useLocalStorageSetting(
  "emailModalOptions",
  DEFAULT_EMAIL_MODAL_OPTIONS
);

const smsOptions = useLocalStorageSetting(
  "smsModalOptions",
  DEFAULT_SMS_MODAL_OPTIONS
);

const emailWidthAndHeight = ref({
  width: DEFAULT_EMAIL_MODAL_OPTIONS.width,
  height: DEFAULT_EMAIL_MODAL_OPTIONS.height,
  minWidth: 500,
  minHeight: 400,
  minimizedWidth: 220,
  minimizedHeight: 53
});

const smsWidthAndHeight = ref({
  width: DEFAULT_SMS_MODAL_OPTIONS.width,
  height: DEFAULT_SMS_MODAL_OPTIONS.height,
  minWidth: 550,
  minHeight: 400,
  minimizedWidth: 220,
  minimizedHeight: 53
});

const isMinimized = ref(false);
const resizableElement = ref<HTMLElement | null>(null);

const emailTemplatesOptions = computed<EmailTemplateOptions | null>(
  () => getters["options/emailTemplates"]
);

const isEmailModal = computed(() => props.type === CommunicationType.email);

const activeModalWidthAndHeight = computed(() =>
  isEmailModal.value ? emailWidthAndHeight.value : smsWidthAndHeight.value
);

const activeModalLocalStorageOptions = computed(() =>
  isEmailModal.value ? emailOptions.value : smsOptions.value
);

const modalType = computed(() =>
  isEmailModal.value ? t("COMMON.EMAIL") : t("COMMON.SMS")
);

const modalClass = computed(() => (route.params.id ? "deal-modal" : ""));

const initPosition = (position: { x: number; y: number }) => {
  const { width, height } = activeModalWidthAndHeight.value;
  const left = props.anchorBottomLeft ? position.x + width : position.x;
  const top = props.anchorBottomLeft ? position.y - height : position.y;
  updatePosition(
    {
      left,
      top,
      width,
      height
    },
    true
  );
};

const updatePosition = (
  positionAndEvent: ResizableEventValues,
  init = false
) => {
  const initOffset = init ? resizableElement.value?.clientWidth || 0 : 0;
  const { left, top, width, height } = positionAndEvent;
  const updatedOptions = {
    left: left - initOffset,
    top: top < 0 ? 0 : top,
    width,
    height
  };
  if (isEmailModal.value) {
    emailOptions.value = {
      ...emailOptions.value,
      ...updatedOptions
    };
    return;
  }
  smsOptions.value = {
    ...smsOptions.value,
    ...updatedOptions
  };
};

const toggleMinimize = () => {
  if (!isMinimized.value) {
    const { width, height } = activeModalLocalStorageOptions.value;
    activeModalLocalStorageOptions.value.restoreHeight = height;
    activeModalLocalStorageOptions.value.restoreWidth = width;
  }
  isMinimized.value = !isMinimized.value;

  const { width, height, minimizedWidth, minimizedHeight } =
    activeModalWidthAndHeight.value;

  const { restoreWidth, restoreHeight, left, top } =
    activeModalLocalStorageOptions.value;

  const setWidth = isMinimized.value ? minimizedWidth : restoreWidth || width;
  const setHeight = isMinimized.value
    ? minimizedHeight
    : restoreHeight || height;

  updatePosition({
    left,
    top,
    width: setWidth,
    height: setHeight
  });
};

const { fetchWrapper: getCommunicationInfo, loading } = usePromiseWrapper(
  async () => {
    if (isEmpty(emailTemplatesOptions.value)) {
      await dispatch("options/getEmailTemplatesOptions");
    }
    const action = isEmailModal.value
      ? emailStore.getApplicationEmailModalData
      : smsStore.getApplicationSmsModalData;
    await action(convertToArray(props.dealId));
    const { left, top } = isEmailModal.value
      ? emailOptions.value
      : smsOptions.value;
    if (!left && !top) {
      initPosition(props.initialValues);
    }
  }
);

onMounted(getCommunicationInfo);
</script>
