<template>
  <div
    ref="lfTableContainer"
    class="relative z-1 hover-row"
    :class="{
      'h-full': !isHeightProvided && fullHeight,
      'freeze-first freeze-border-first':
        pinOption &&
        pinEnabled &&
        !showMultiselectControls &&
        !freezeFirstTwoColumnsWhenPinned,
      'freeze-first-two freeze-border-first-two':
        pinOption &&
        pinEnabled &&
        (showMultiselectControls || freezeFirstTwoColumnsWhenPinned)
    }"
  >
    <div
      :id="customId || ID"
      class="custom-table-scrollbar overflow-y-overlay"
      data-cy="custom-table-scrollbar"
      :class="[
        { 'fixed-height': isHeightProvided },
        hideScrollbarX
          ? 'overflow-x-hidden hover:overflow-x-overlay'
          : 'overflow-x-overlay'
      ]"
    >
      <table
        class="table-auto bg-white rounded w-full px-1 shadow-sm"
        :class="[noLeftRightBorder, { border: hasBorder }]"
        :data-cy="attrs?.['data-cy'] || 'lf-table'"
      >
        <table-head
          :class="[
            'table-head',
            noLeftRightBorder,
            { 'sticky top-0 bg-white z-50': headerSticky }
          ]"
          :select-all="selectAll"
          :select-one="selectOne"
          :show-head-select-all="showHeadSelectAll"
          :has-select-mode-option="hasSelectModeOption"
          :is-select-mode-enabled="isSelectModeEnabled"
          :can-disable-select-mode="canDisableSelectMode"
          :columns="columns"
          :scrollable-element-id="scrollableElementId"
          :select-title="selectTitle"
          :header-no-wrap="headerNoWrap"
          :is-loading="isTableLoading"
          :is-selected-all-rows="isSelectedAllRows"
          :head-classes="headClasses"
          :pin-enabled="pinEnabled"
          :pin-option="pinOption"
          :disabled-checkboxes="disabledCheckboxes"
          @toggle-all-checkboxes="handleToggleAllCheckboxes"
          @toggle-sub-column="toggleSubColumns"
          @click:pin="handlePinClick"
          @multiselect:change="handleMultiselectChange"
        >
          <slot name="title" />
        </table-head>
        <tbody class="text-headline text-left">
          <slot name="controls" />
          <table-body
            :select-all="selectAll"
            :select-one="selectOne"
            :select-one-key="selectOneKey"
            :selected-rows="selectedRows"
            :columns="columns"
            :data="data"
            :accordion-table="accordionTable"
            :row-is-clickable="rowIsClickable"
            :row-is-inactive="rowIsInactive"
            :row-click-condition="rowClickCondition"
            :select-details-provider="selectDetailsProvider"
            :no-data-text="noDataText"
            :has-border="rowWithBorder"
            :disabled-checkboxes="disabledCheckboxes"
            :lazy-load="lazyLoad"
            @row-clicked="rowClicked"
            @row-changed="rowChanged"
            @radio-selected="radioSelected"
          />
        </tbody>
      </table>
    </div>
    <lf-pagination
      v-if="metadata"
      :style="paginationStyle"
      :show-per-page="showPerPage"
      :metadata="metadata"
      :is-absolute="paginationAbsolute"
      @change-page="changePage"
      @change-items-per-page="changeItemsPerPage"
    />
    <!-- adding blur to the right edge to indicate scolling -->
    <div
      v-if="hideScrollbarX"
      class="h-full w-8 bg-gradient-to-r from-transparent to-white absolute top-0 right-0 bg-opacity-20"
    />
    <div v-if="isTableLoading" class="absolute inset-0">
      <div class="h-full w-full bg-white">
        <loader
          class="table-loader"
          :is-loading="isTableLoading"
          :z-index="40"
        />
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import TableHead from "@/components/ui/table/TableHead.vue";
import TableBody from "@/components/ui/table/TableBody.vue";
import LfPagination from "@/components/ui/table/LfPagination.vue";
import { ref, watch, onMounted, useAttrs, computed, inject } from "vue";
import map from "lodash/map";
import intersection from "lodash/intersection";
import { useElementSize } from "@vueuse/core";
import { HEIGHT_REGEX_PATTERN } from "@/helpers/UI";
import { CLEAR_LF_TABLE_ROWS } from "@/helpers/constants/events";
import { useEmitter } from "@/hooks/common";

import type { PropType } from "vue";
import type {
  SelectDetailsProvider,
  IResponseMeta,
  LazyLoadOptions
} from "@/models/common";
import type { IDealsColumn } from "@/models/applications";
import uniq from "lodash/uniq";

const props = defineProps({
  columns: {
    type: Array as PropType<Array<IDealsColumn>>,
    default: null
  },
  data: {
    type: [Array, Object] as PropType<unknown[] | Record<string, unknown>>,
    default: null
  },
  metadata: {
    type: Object as PropType<IResponseMeta>,
    default: null
  },
  selectAll: {
    type: Boolean,
    default: false
  },
  selectOne: {
    type: Boolean,
    default: false
  },
  selectOneKey: {
    type: String,
    default: ""
  },
  showHeadSelectAll: {
    type: Boolean,
    default: true
  },
  rowIsClickable: {
    type: Boolean,
    default: false
  },
  rowIsInactive: {
    type: Function,
    default: () => false
  },
  rowClickCondition: {
    type: Function,
    default: () => true
  },
  selectDetailsProvider: {
    type: [Function, Object] as PropType<SelectDetailsProvider>,
    default: null
  },
  selectTitle: {
    type: String,
    default: ""
  },
  showPerPage: {
    type: Boolean,
    default: true
  },
  noDataText: {
    type: String,
    default: "No Data Available"
  },
  hideScrollbarX: {
    type: Boolean,
    default: false
  },
  maxTableHeight: {
    type: String,
    default: "100px"
  },
  headerNoWrap: {
    type: Boolean,
    default: true
  },
  isTableLoading: {
    type: Boolean,
    default: false
  },
  customId: {
    type: String
  },
  checkedRows: {
    type: Array as PropType<(string | number)[]>,
    default: () => []
  },
  scrollableElementId: {
    type: String,
    default: ""
  },
  hasBorder: {
    type: Boolean,
    default: true
  },
  rowWithBorder: {
    type: Boolean,
    default: true
  },
  headClasses: {
    type: String,
    default: "border"
  },
  accordionTable: {
    type: Boolean,
    default: false
  },
  paginationAbsolute: {
    type: Boolean,
    default: true
  },
  pinOption: {
    type: Boolean,
    default: false
  },
  pinEnabled: {
    type: Boolean,
    default: false
  },
  freezeFirstTwoColumnsWhenPinned: {
    type: Boolean,
    default: false
  },
  fullHeight: {
    type: Boolean,
    default: true
  },
  headerSticky: {
    // In order for header to be fixed, the container is required to have a fixed height
    // Check the DealsView to see the example class being applied
    type: Boolean,
    default: false
  },
  disabledCheckboxes: {
    type: Boolean,
    default: false
  },
  lazyLoad: {
    type: [Boolean, Object] as PropType<boolean | LazyLoadOptions>,
    default: true
  },
  hasSelectModeOption: {
    type: Boolean,
    default: false
  },
  isSelectModeEnabled: {
    type: Boolean,
    default: false
  },
  canDisableSelectMode: {
    type: Boolean,
    default: true
  },
  showMultiselectControls: {
    type: Boolean,
    default: false
  }
});

const SCROLLBAR_WIDTH = 8;
const TABLE_PAGINATION_HEIGHT_PX = 70;

const emitter = useEmitter();

const attrs = useAttrs();
const emit = defineEmits([
  "selectedRow",
  "selectedRows",
  "row-clicked",
  "changePage",
  "changeItemsPerPage",
  "toggleSubColumn",
  "update:pinEnabled",
  "multiselect:change"
]);

const ID = inject("ID", undefined);
const selectedRows = ref(props.checkedRows);
const lfTableContainer = ref<HTMLDivElement | null>(null);
const { height: lfTableContainerHeight } = useElementSize(lfTableContainer);

const tableHeight = computed(() => {
  let value = lfTableContainerHeight.value;
  if (!props.paginationAbsolute) {
    // Reducing by pagination height + the width of the scrollbar
    // to have a gap between the table and pagination component
    value = Math.floor(value - (TABLE_PAGINATION_HEIGHT_PX + SCROLLBAR_WIDTH));
  }
  return value;
});

const paginationStyle = computed(() => {
  const style = new Map();
  style.set("height", TABLE_PAGINATION_HEIGHT_PX + "px");
  if (!props.paginationAbsolute) {
    style.set("marginTop", SCROLLBAR_WIDTH + "px");
  }
  return Object.fromEntries(style);
});

const isHeightProvided = computed(() => {
  const classes = (attrs.class as string) || "";
  return classes.match(HEIGHT_REGEX_PATTERN);
});

const isSelectedAllRows = computed(() => {
  return (
    !!props.data?.length &&
    intersection(selectedRows.value, map(props.data, "id"))?.length ===
      props.data?.length
  );
});

const handleToggleAllCheckboxes = (isChecked: boolean) => {
  const ids = map(props.data, "id");
  if (isChecked) {
    selectedRows.value = uniq([...selectedRows.value, ...ids]);
  } else {
    selectedRows.value = selectedRows.value.filter((id) => !ids.includes(id));
  }

  emit("selectedRows", selectedRows.value, isChecked);
};

const handleMultiselectChange = (isMultiselectEnabled: boolean) => {
  selectedRows.value = [];
  emit("multiselect:change", isMultiselectEnabled);
};

const rowChanged = (checked: boolean, id: string | number) => {
  const numId = Number(id);
  const changedValue = isNaN(numId) ? id : numId;
  if (checked) {
    selectedRows.value.push(changedValue);
  } else {
    selectedRows.value.splice(selectedRows.value.indexOf(changedValue), 1);
  }
  emit("selectedRow", id, checked);
  emit("selectedRows", selectedRows.value);
};

const radioSelected = (id: string | number) => {
  selectedRows.value = [id];
  emit("selectedRow", id, true);
};

const noLeftRightBorder = (attrs as { class: string })?.class?.includes(
  "freeze-border-last"
)
  ? "border-l-0 border-r-0"
  : "";

const rowClicked = (value: unknown) => {
  emit("row-clicked", value);
};

const changePage = (page: number) => {
  emit("changePage", page);
};

const changeItemsPerPage = (itemsPerPage: number, maxPage: number | null) => {
  emit("changeItemsPerPage", itemsPerPage, maxPage);
};

const toggleSubColumns = (key: string) => {
  emit("toggleSubColumn", key);
};

const handlePinClick = (value: boolean) => {
  emit("update:pinEnabled", value);
};

watch(
  () => props.checkedRows,
  (val) => {
    selectedRows.value = val;
  }
);

onMounted(() => {
  emitter?.on(CLEAR_LF_TABLE_ROWS, () => {
    selectedRows.value = [];
  });
});

defineExpose({
  selectedRows
});
</script>
<style>
.items-list table,
.items-list thead {
  @apply border-none;
}

.items-list table {
  /* couldn't use tailwind utility here */
  border-collapse: separate !important;
  border-spacing: 0 1rem !important;
  background-color: transparent !important;
  box-shadow: none !important;
  --tw-shadow: none !important;
}

.items-list tr {
  @apply text-gray-700;
  @apply bg-white;
  @apply rounded-full;
}

.items-list th {
  @apply bg-disabled;
}

.items-list td {
  @apply text-gray-400;
}

.items-list table tr td:first-child {
  @apply rounded-l;
}

.items-list table tr td:last-child {
  @apply rounded-r;
}

.border-first table th:first-child,
.border-first table td:first-child {
  @apply border;
}

.border-last table th:last-child,
.border-last table td:last-child {
  @apply border;
}

.freeze-first table td:first-child,
.freeze-first table th:first-child {
  background: white;
  border-left: 1px solid transparent;
  position: sticky !important;
  left: 0;
  z-index: 2;
}

.freeze-first-two table td:nth-child(-n + 2),
.freeze-first-two table th:nth-child(-n + 2) {
  background: white;
  border-left: 1px solid transparent;
  position: sticky;
  z-index: 2;
}

.freeze-first-two table th:nth-child(-n + 2) {
  top: 0;
}

.freeze-first-two table td:first-child,
.freeze-first-two table th:first-child {
  left: 0;
}

.freeze-first-two table td:nth-child(2),
.freeze-first-two table th:nth-child(2) {
  left: 56px;
}

.freeze-first-two table td.bg-divider {
  @apply bg-grey-hover;
  top: 49px;
}

.freeze-first-two table tr:hover td:nth-child(-n + 2) {
  @apply bg-grey-hover;
}

.freeze-first-two table td:first-child:hover,
.freeze-first-two table td:nth-child(2):hover {
  @apply bg-grey-hover;
}

.freeze-first-two table tr.selected-row td {
  background: #edf4fe;
}

.freeze-first-two table tr.selected-row:hover td {
  background: #edf4fe;
}

.freeze-border-first-two table th:nth-child(2):after,
.freeze-border-first-two table td:nth-child(2):after {
  content: "";
  position: absolute;
  top: 0;
  right: -30px;
  height: 100%;
  width: 30px;
  box-shadow: 15px 0 30px -30px gray inset !important;
  pointer-events: none;
}

.freeze-border-first table th:first-child:before,
.freeze-border-first table td:first-child:before,
.freeze-border-last table th:last-child:before,
.freeze-border-last table td:last-child:before {
  position: absolute;
  content: "";
  left: -1px;
  right: -1px;
  top: 0;
  height: 100%;
  width: 100%;
  pointer-events: none;
}

.freeze-border-first table th:first-child:after,
.freeze-border-first table td:first-child:after {
  content: "";
  position: absolute;
  top: 0;
  right: -30px;
  height: 100%;
  width: 30px;
  box-shadow: 15px 0 30px -30px gray inset !important;
  pointer-events: none;
}

.freeze-border-last table th:last-child:after,
.freeze-border-last table td:last-child:after {
  content: "";
  position: absolute;
  top: 0;
  left: -30px;
  height: 100%;
  width: 30px;
  box-shadow: -15px 0 30px -30px gray inset !important;
  pointer-events: none;
}

.table-head {
  box-shadow:
    0 1px rgb(228, 231, 235) inset,
    0 -1px rgb(228, 231, 235) inset;
}

.freeze-last table td:last-child,
.freeze-last table th:last-child {
  background: white;
  position: sticky !important;
  right: 0;
  z-index: 2;
}

.freeze-last table th:last-child,
.freeze-first table th:first-child {
  z-index: 3;
}

.freeze-header table th {
  background: white;
  position: sticky !important;
  top: 0;
  z-index: 2;
}

.freeze-border-header table th:before {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  border-top-width: 1px;
  border-bottom-width: 1px;
  pointer-events: none;
}

.freeze-border-header table {
  border-top: none;
}

.freeze-border-header thead {
  border: none;
}

table tbody th {
  position: sticky;
  left: 0;
  background: white;
  z-index: 0;
}

.hover-row table tbody tr:hover {
  @apply bg-grey-hover;
}

.table-loader {
  max-height: 85vh;
}

.fixed-height {
  height: v-bind(tableHeight + "px");
}

@-moz-document url-prefix() {
  .fixed-height {
    overflow-y: auto;
    overflow-x: auto;
    scrollbar-gutter: stable;
  }
}

.custom-table-scrollbar::-webkit-scrollbar {
  background: transparent;
  height: v-bind(SCROLLBAR_WIDTH + "px");
  width: v-bind(SCROLLBAR_WIDTH + "px");
}

.custom-table-scrollbar::-webkit-scrollbar-corner {
  background: transparent;
}

.custom-table-scrollbar::-webkit-scrollbar-thumb {
  border-radius: 100px;
  background-color: rgba(133, 133, 133, 0.5);
  -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
  box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}
</style>
