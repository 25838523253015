export const PLACEMENT_NO_WFB_TEMPLATE = "PLACEMENT_NO_WFB_TEMPLATE" as const;
export const PLACEMENT_NO_ORIGIN_OFFER_TEMPLATE =
  "PLACEMENT_NO_ORIGIN_OFFER_TEMPLATE" as const;
export const PLACEMENT_NO_DESTINATION_OFFER_TEMPLATE =
  "PLACEMENT_NO_DESTINATION_OFFER_TEMPLATE" as const;
export const SCORECARD_ATTRIBUTE_DRAG_CHANGED =
  "SCORECARD_ATTRIBUTE_DRAG_CHANGED" as const;
export const ORCHESTRATION_DRAG_VAL_CHANGED =
  "ORCHESTRATION_DRAG_VAL_CHANGED" as const;
export const CLEAR_LF_TABLE_ROWS = "CLEAR_LF_TABLE_ROWS" as const;
export const CLOSE_SIDE_PANEL_SECTIONS = "CLOSE_SIDE_PANEL_SECTIONS" as const;
export const GO_TO_SMARTVIEW = "GO_TO_SMARTVIEW" as const;
export const EMAIL_UPDATED = "EMAIL_UPDATED" as const;
export const SMS_UPDATED = "SMS_UPDATED" as const;
