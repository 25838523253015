import type {
  IFile,
  IUserNotification,
  IPii,
  IResponseMeta,
  Identifiable
} from "./common";
import type { IFunder } from "./funders";
import type { BusinessCreditService } from "./options";
import type { IUser } from "./users";
import type { IIntegration } from "./authentications";
import type { EmailTemplateVisibility } from "@/enums/emailCustomization";
import type { TEMPLATE_STATUSES } from "@/helpers/constants";
import type { LenderNetwork } from "@/models/lenderNetwork";
import type { IntegrationFunder } from "@/models/clientCredentials";

export interface IDnsRecord {
  name: string;
  record_type: string;
  value: string;
  created_at: string;
  updated_at: string;
}

export interface DomainStatus {
  created_at: string;
  updated_at: string;
  domain_name: string;
  domain_identity_status: string;
  from_email: string;
  from_person: string;
  reply_to_email: string;
  id: number;
  is_primary: boolean;
  widget_path: string | null;
}

export interface NameId {
  name: string;
  id: string;
}

export interface IWhitelabel {
  id: number;
  domain_name: string;
  domain_identity_status: string;
  from_email?: string;
  from_person?: string;
  reply_to_email?: string;
  created_at: string;
  updated_at: string;
  is_primary: boolean;
  aliases_count: number;
  use_in_communication_emails: boolean;
}

export interface IDocusignTemplate {
  template_id: number;
  type: string;
  has_guarantor: boolean;
}

export interface IEmailCustomization {
  id: number;
  template_name: string;
  logo_url: null | string;
  header_image: null | string;
  header_text_color: null | string;
  header_primary_color: null | string;
  footer_business_address: null | string;
  footer_business_phone: null | string;
  active: boolean;
  linkedin_url: null | string;
  twitter_url: null | string;
  facebook_url: null | string;
  business_name: string;
  footer_text: null | string;
}

export interface ExperianSubcodes {
  [key: string]: ExperianSubcodeValues;
}

export interface ExperianSubcodeValues {
  fico8?: string;
  fico9?: string;
  fico_v2?: string;
  vantage4?: string;
  fico_advanced?: string;
}

export interface SimpleClient {
  id: string;
  name: string;
}

export interface IClient extends Identifiable {
  activity_hub_call_webhook_url: string | null;
  activity_hub_task_webhook_url: string | null;
  available_widget_countries: string[];
  billing_client_name: string;
  billing_contact_name: string | null;
  billing_email: string | null;
  billing_title: string | null;
  business_credit_services: BusinessCreditService[];
  can_create_underwriter: boolean;
  can_manage_attribute_builder: boolean;
  can_manage_client_credentials: boolean;
  can_manage_data_orchestration: boolean;
  can_manage_docusign: boolean;
  has_docusign_liveness: boolean;
  can_manage_score_cards: boolean;
  can_manage_workflow_builder: boolean;
  company_website: string | null;
  credit_webhook: string | null;
  custom_subdomain: string | null;
  docusign_template?: IDocusignTemplate;
  docusign_webhook: string | null;
  equipment_rental_enabled: boolean;
  experian_subcodes?: ExperianSubcodes;
  files?: IFile[];
  has_active_email_template: boolean;
  has_custom_emails_enabled: boolean;
  has_email_integration: boolean;
  has_linked_funder: boolean;
  id: string;
  in_test_mode: boolean;
  ip_addresses?: string[];
  is_enforcing_password_ttl: boolean;
  is_plaid_connected: boolean;
  is_redirect_to_borrower_platform_enabled: boolean;
  lender_network: Pick<LenderNetwork, "id" | "name"> | null;
  lender_network_enabled: boolean;
  linked_funder: IFunder | null;
  maskedPiis: IMaskedPii[];
  mca_track_bearer_token?: string;
  mca_track_funder_key?: string;
  mca_track_iso_ids?: string[];
  mca_track_isos: NameId[];
  name: string;
  notifications?: IUserNotification[];
  offer_webhook: string | null;
  owner?: IUser;
  password_ttl_days: number;
  phone: string | null;
  piis?: IPii[];
  revenue_share: number;
  send_docusign_email: boolean;
  send_plaid_email: boolean;
  stop_experian_dnb_services: boolean;
  subscription_type: number;
  underwriting_webhook: string | null;
  website: string;
  whitelabels?: IWhitelabel[] | null;
  widget_token: string;
  linked_funder_id: string | null;
}

export type IClientUpdatePayload = Partial<IClient> & {
  owner_id?: number;
};

export interface IClientPreference {
  id: number;
  key: string;
  value: [];
  created_at: string;
  updated_at: string;
}

export interface IUpdatePiisPayload {
  revenue_share: number;
  account_number: string;
  routing_number: string;
}

export interface IPasswordExpiryPayload {
  is_enforcing_password_ttl: boolean;
  password_ttl_days: string;
}

export interface IClientsState {
  meta: IResponseMeta;
  all: IClient[];
  active?: IClient | null;
  activeClientIntegration: IIntegration;
  activeClientCredentialsRules: ClientCredentialsRules;
  activeClientCredentialsValues: ActiveClientCredentialsValues;
  activeClientFunderIntegrations: IntegrationFunder[];
  plaidLinkToken: null | string;
  plaidPublicToken: null | string;
  emailTemplates: IEmailCustomization[];
  emailTemplatesForTestMail: IEmailCustomization[];
  clientsLoading: boolean;
  dnsRecords: IDnsRecord[];
  domainStatus: DomainStatus | null;
  embedReportData: IClientEmbedReportData;
}

export interface ActiveClientCredentialsValues {
  data: ClientCredentials[];
  credentials?: ClientCredentialsValues;
}

export interface ClientCredentials {
  id: number;
  is_active: boolean;
  created_at: string;
  updated_at: string;
  service_name: string;
  service_type?: string;
  credentials: ClientCredentialsValues;
  files?: Record<string, boolean>;
  data?: ClientCredentials[];
}

export interface ClientCredentialsValues {
  [key: string]: string | Record<string, string> | boolean;
}

export interface ClientCredentialsRules {
  [key: string]: ClientCredentialsRule;
}

export interface ClientCredentialsRule {
  [key: string]: Record<number, string>;
}

export interface IClientEmbedReportData {
  embed_token: null | string;
  embed_id: null | string;
}

export interface IMaskedPii {
  id: number;
  key: string;
}

export enum IClientEmbedReportOption {
  BusinessCreditReport = "1",
  IntegrationReport = "2"
}

export interface EmailCustomizationViewParams {
  page?: number;
  per_page?: number;
  search?: string;
}

export interface EmailTemplate {
  id: string;
  created_at: string;
  updated_at: string;
  visibility?: EmailTemplateVisibility[keyof EmailTemplateVisibility];
  user?: IUser;
  name?: string;
  description: string;
  intro?: string;
  subject?: string;
  body?: string;
  footer?: string;
  dynamic_fields?: string[];
  client?: IClient;
  status?: number;
  is_lendflow_template?: boolean;
  approval_status?: keyof typeof TEMPLATE_STATUSES;
}

export type CreateClientPayload = {
  first_name: string;
  last_name: string;
  company_name: string;
  phone: string;
  email: string;
  password: string;
  password_confirmation: string;
  company_website: string;
};

export type WhitelabelAlias = {
  user: IUser;
  whitelabel_id: number;
  email_signature: string;
  id: string;
};

export type SingleWhitelabelAliasPayload = {
  clientId: string;
  whitelabelId: IWhitelabel["id"];
  aliasId: WhitelabelAlias["id"];
};

export type StoreWhitelabelAliasesPayload = {
  clientId: string;
  whitelabelId: WhitelabelAlias["whitelabel_id"];
  userIds: WhitelabelAlias["user"]["id"][];
};

export type GetWhitelabelAliasPayload = {
  clientId: string;
  whitelabelId: IWhitelabel["id"];
};
