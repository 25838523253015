import cloneDeep from "lodash/cloneDeep";

import WORKFLOW_BLOCK_COMPONENTS, {
  type WorkflowBlockComponentsKey
} from "@/helpers/constants/WorkflowComponentMap";
import type {
  ApplicationStage,
  DoStatus,
  EmailDefinition,
  IWorkflow,
  IWorkflowStage,
  NotificationItem,
  OwnerWFL,
  SmsDefinition,
  WfbFunderRecord,
  WorkflowBlockIds,
  WorkflowEmailDefinition
} from "@/models/workflows";

import UnknownBlock from "@/components/deals/WorkflowLive/UnknownBlock.vue";
import compact from "lodash/compact";
import { wordsFirstLetterToUpper } from "@/helpers/formatting";
import type { Ref } from "vue";

export const getWorkflowComponent = (blockId: WorkflowBlockComponentsKey) =>
  WORKFLOW_BLOCK_COMPONENTS[blockId] ?? UnknownBlock;

export const websiteAddressChange = (e: Event) => {
  const pattern = /^((http|https):\/\/)/;
  const websiteAddress = (e.target as HTMLInputElement).value;

  if (websiteAddress !== "" && !pattern.test(websiteAddress)) {
    return `https://${websiteAddress}`;
  }

  return websiteAddress;
};

export const deletePiiMaskedFields = <T extends Record<string, unknown>>(
  obj: T,
  maskedFields: string[]
) => {
  maskedFields.forEach((value: keyof T) => {
    if (
      obj[value] &&
      typeof obj[value] === "string" &&
      !!(obj[value] as string).match(/^\*{5}/)
    ) {
      delete obj[value];
    }
  });
  return obj;
};

export const sortOwnersArray = (owners: Array<OwnerWFL>) =>
  cloneDeep(owners).sort((a, b) => {
    if (a.personal_is_primary) return -1;
    if (b.personal_is_primary) return 1;
    return 0;
  });

export const isPiiMaskedField = (value: string): boolean =>
  !!value.match(/^\*{5}/);

export const getValidBlockIds = (
  ids: WorkflowBlockIds[],
  stageType: ApplicationStage,
  workflowTemplate: IWorkflow
) => {
  const blocks = workflowTemplate.content?.stages
    ?.filter((stage) => stage.type === stageType)
    .flatMap((stage) => stage.tabs)
    .flatMap((tab) => tab?.blocks);
  const validIds = blocks?.map((block) => block?.id);
  return ids.reduce((acc: WorkflowBlockIds[], id) => {
    if (validIds?.includes(id)) {
      acc.push(id);
    }
    return acc;
  }, []);
};

export const isOfferStage = (
  stage: IWorkflowStage | null
): stage is Omit<IWorkflowStage, "funders"> & {
  funders: WfbFunderRecord[];
} => {
  if (!stage) {
    return false;
  }
  return stage.type === "offer";
};

export const getActiveNotifications = (
  notifications: WorkflowEmailDefinition[],
  activeTemplate: IWorkflow
) => {
  const activeTemplateStages = (activeTemplate?.content?.stages || []).map(
    ({ name, type }) => ({ name, value: type })
  );

  return notifications.map((notification) => {
    if (!notification.stages) {
      return notification;
    }
    const applicationCreatedStage = notification.stages.find(
      ({ value }) => value === "created"
    );
    const deadStage = notification.stages.find(({ value }) => value === "dead");
    return {
      ...notification,
      stages: compact([
        applicationCreatedStage,
        ...activeTemplateStages,
        deadStage
      ])
    };
  });
};

export const getDefaultNotification = (
  notification: WorkflowEmailDefinition,
  value: string,
  includeBrandingAndTemplateIdKeys = true
) => {
  const defaultNotification: NotificationItem = {
    group: notification.group,
    type: notification.type,
    enabled: false
  };

  if (includeBrandingAndTemplateIdKeys) {
    defaultNotification.branding_id = null;
    defaultNotification.template_id = null;
  }
  if (notification.stages) {
    defaultNotification.stage = value;
  }

  if (notification.statuses) {
    defaultNotification.status = value;
  }

  if (notification.intervals) {
    defaultNotification.interval = value;
  }

  if (notification.events) {
    defaultNotification.event = value;
  }

  return defaultNotification;
};

export const getUpdatedNotificationIndex = (
  target: NotificationItem,
  notifications: NotificationItem[]
) => {
  return notifications?.findIndex((notification) => {
    if (
      notification.type !== target.type ||
      notification.group !== target.group
    ) {
      return false;
    }

    if (notification.stage) {
      return notification.stage === target.stage;
    }

    if (notification.interval) {
      return notification.interval === target.interval;
    }

    if (notification.status) {
      return notification.status === target.status;
    }

    if (notification.event) {
      return notification.event === target.event;
    }

    return false;
  });
};

export const getStageNotificationName = (
  stage: string,
  notifications: WorkflowEmailDefinition[]
) => {
  const notificationStages = notifications.find(({ type }) =>
    ["application_stage_changed"].includes(type)
  )?.stages;

  return notificationStages?.find(({ value }) => value === stage)?.name || "-";
};

export const getEventNotificationName = (
  event: string,
  notifications: WorkflowEmailDefinition[]
) => {
  const notificationEvents = notifications.find(({ type }) =>
    ["offer_progress"].includes(type)
  )?.events;

  return notificationEvents?.find(({ value }) => value === event)?.name || "-";
};
export const getNotificationName = (
  notification: NotificationItem,
  notifications: WorkflowEmailDefinition[]
) => {
  if (notification.stage) {
    return getStageNotificationName(notification.stage, notifications);
  }
  if (notification.event) {
    return getEventNotificationName(notification.event, notifications);
  }
  const { interval, status } = notification;
  const name = interval ?? status;
  return wordsFirstLetterToUpper(name, "_");
};

export const handleUpdateAllToggle = (
  group: string,
  notifications: WorkflowEmailDefinition[],
  renewedEmailNotifications: Ref<NotificationItem[]>,
  selectAllNotifications: Ref<Record<string, boolean>>
) => {
  const types = [
    ...new Set(notifications.map((notification) => notification.type) ?? [])
  ];
  types.forEach(
    (type) =>
      (selectAllNotifications.value[`${type}_${group}`] =
        renewedEmailNotifications.value.some(
          (notification) =>
            notification.type === type &&
            notification.group === group &&
            (notification.enabled ||
              notification.branding_id ||
              notification.template_id)
        ))
  );
};

export const handleUpdateTemplate = (
  templates: NotificationItem[],
  allNotifications: Ref<NotificationItem[]>
) => {
  templates.forEach((template) => {
    const index = allNotifications.value.findIndex(
      ({ group, type, status, stage, interval, event }) => {
        const haveSettingsMatch =
          (status && status === template.status) ||
          (stage && stage === template.stage) ||
          (interval && interval === template.interval) ||
          (event && event === template.event);

        const typeAndGroupMatch =
          group === template.group && type === template.type;

        return notificationHasSubSettings(template)
          ? typeAndGroupMatch && haveSettingsMatch
          : typeAndGroupMatch;
      }
    );
    if (index === -1) {
      return;
    }

    allNotifications.value[index] = template;
  });
};

export const notificationHasSubSettings = <
  T extends {
    stages?: { name: string; value: string }[];
    events?: { name: string; value: string }[];
    statuses?: DoStatus[];
    intervals?: string[];
    stage?: string;
    status?: string;
    interval?: string;
    event?: string;
  }
>({
  stages,
  statuses,
  intervals,
  events,
  stage,
  status,
  interval,
  event
}: T) =>
  [stages, statuses, intervals, events, stage, status, interval, event].some(
    (item) => item !== undefined
  );

export const getNotificationData = (
  notification: WorkflowEmailDefinition,
  activeNotifications: NotificationItem[] | undefined,
  value: string,
  type: "email" | "sms"
): NotificationItem => {
  const savedNotification = activeNotifications?.find((activeNotification) => {
    if (
      activeNotification.group !== notification.group ||
      activeNotification.type !== notification.type
    ) {
      return false;
    }

    const { stage, interval, status, event } = activeNotification;
    return [stage, interval, status, event].some((val) => val && val === value);
  });

  return (
    savedNotification ??
    getDefaultNotification(notification, value, type === "email")
  );
};

export const getSimpleNotificationData = (
  notification: WorkflowEmailDefinition,
  activeNotifications: NotificationItem[] | undefined
): NotificationItem => {
  const savedNotification = activeNotifications?.find(
    ({ group, type }) =>
      group === notification.group && type === notification.type
  );

  return (
    savedNotification ?? {
      group: notification.group,
      type: notification.type,
      enabled: false
    }
  );
};

export const prepareAllNotifications = (
  notificationsWithActiveStages: EmailDefinition[] | SmsDefinition[],
  allNotifications: Ref<NotificationItem[]>,
  activeTemplateNotifications: NotificationItem[] | undefined,
  type: "email" | "sms"
) => {
  const notifications = notificationsWithActiveStages?.flatMap(
    (notification) =>
      type === "email"
        ? (notification as EmailDefinition).emails
        : (notification as SmsDefinition).sms_notifications
  );
  allNotifications.value = notifications.flatMap((notification) => {
    if (notification.intervals) {
      return notification.intervals.map((interval) =>
        getNotificationData(
          notification,
          activeTemplateNotifications,
          interval,
          type
        )
      );
    } else if (notification.stages) {
      return notification.stages.map((stage) =>
        getNotificationData(
          notification,
          activeTemplateNotifications,
          stage.value,
          type
        )
      );
    } else if (notification.statuses) {
      return notification.statuses.map((status) =>
        getNotificationData(
          notification,
          activeTemplateNotifications,
          status,
          type
        )
      );
    } else if (notification.events) {
      return notification.events.map((event) =>
        getNotificationData(
          notification,
          activeTemplateNotifications,
          event.value,
          type
        )
      );
    } else if (!notificationHasSubSettings(notification)) {
      return getSimpleNotificationData(
        notification,
        activeTemplateNotifications
      );
    } else {
      return [];
    }
  });
};
