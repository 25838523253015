<template>
  <div
    class="flex flex-col space-y-3 pt-6 pb-2 h-[calc(100%-53px)] overflow-y-auto"
  >
    <loader :is-loading="loading" />
    <div class="flex w-full items-start space-x-2 px-6">
      <div class="w-1/5 font-semibold text-label">
        {{ $t("SUBMISSIONS.SEND_TO") }}
      </div>
      <div class="w-4/5 relative flex justify-start">
        <div class="w-full">
          <multiselect-dropdown
            class="w-full multiselect-dropdown"
            name="to"
            placeholder=""
            :value="selectedRecipients"
            :options="recipientOptions"
            is-absolute-positioned
            scrollable-parent="do-service"
            :allow-trim="false"
            @change="handleUpdateRecipients"
          />
        </div>
      </div>
    </div>
    <div class="grow">
      <dynamic-field
        name="smsText"
        class="sms-headless"
        :placeholder="$t('ACTIVITY.SMS.MESSAGE_PLACEHOLDER')"
        :model-value="smsContent"
        :type="CommunicationType.sms"
        has-length-counter
        :max-length="MAX_SMS_MESSAGE_LENGTH"
        @clean-content:update="handleCleanContentUpdate"
        @update:model-value="smsContent = $event"
      />
      <div
        ref="sendButtonBlockRef"
        class="bg-disabled p-2 mx-2 rounded-b border border-t-0 flex items-center"
        :class="isMessageTooLong ? 'justify-between' : 'justify-end'"
      >
        <span v-if="isMessageTooLong" class="text-error">
          {{ $t("ACTIVITY.SMS.TOO_LONG_MESSAGE") }}
        </span>
        <div class="flex items-center space-x-4">
          <span v-if="modalData.from_number" class="font-semibold">
            {{ modalData.from_number }}
          </span>
          <primary-button
            data-cy="send-button-sms"
            class="send-button"
            :disabled="isSendingDisabled"
            @click="sendSms"
          >
            <icon-base :icon="IconSend" class="text-white" />
          </primary-button>
        </div>
      </div>
      <primary-button
        data-cy="send-button-2"
        class="send-button"
        :disabled="isSendingDisabled"
        :class="isSendButtonBlockVisible ? 'hidden' : 'sticky bottom-0 right-3'"
        @click="sendSms"
      >
        <icon-base :icon="IconSend" class="text-white" />
      </primary-button>
    </div>
  </div>
</template>
<script setup lang="ts">
import DynamicField from "@/components/DynamicField.vue";
import IconBase from "@/components/ui/IconBase.vue";
import IconSend from "@/components/icons/IconSend.vue";
import PrimaryButton from "@/components/ui/buttons/PrimaryButton.vue";
import MultiselectDropdown from "@/components/ui/inputs/MultiselectDropdown.vue";

import { storeToRefs } from "pinia";
import { computed, ref } from "vue";
import useSmsStore from "@/stores/sms";
import { usePromiseWrapper } from "@/hooks/common";
import type { MultiSelectOption } from "@/models/options";
import { v4 as uuid } from "uuid";
import { useI18n } from "vue-i18n";
import { useElementVisibility } from "@vueuse/core";
import communicationService from "@/services/modules/communication";
import { CommunicationType } from "@/enums/communicationLogs";

const emit = defineEmits(["close-dialog"]);

const MAX_SMS_MESSAGE_LENGTH = 160;

const store = useSmsStore();
const { modalData } = storeToRefs(store);
const sendButtonBlockRef = ref<HTMLElement | null>(null);

const isSendButtonBlockVisible = useElementVisibility(sendButtonBlockRef);

const smsContent = ref("");
const smsText = ref("");
const isMessageTooLong = ref(false);

const { t } = useI18n();

const ownersWithApplications = computed(() =>
  modalData.value.applications.map(({ owners, id }) => ({ owners, id })).flat()
);

const recipientOptions = computed(() => {
  return ownersWithApplications.value.reduce(
    (
      result: (MultiSelectOption & {
        applicationId: string;
        telephone: string;
        isPrimary?: boolean;
      })[],
      { owners, id: applicationId }
    ) => {
      owners.forEach((owner) => {
        const label = owner.is_primary ? `(${t("COMMON.PRIMARY")})` : "";
        result.push({
          id: uuid(),
          name: `${owner.full_name} ${owner.telephone} ${label}`.trim(),
          telephone: owner.telephone,
          isPrimary: owner.is_primary,
          applicationId
        });
        owner.additional_telephones.forEach((telephone) => {
          result.push({
            id: uuid(),
            name: `${owner.full_name} ${telephone}`,
            telephone,
            applicationId
          });
        });
      });
      return result;
    },
    []
  );
});

const selectedRecipients = ref<Pick<MultiSelectOption, "id">[]>(
  recipientOptions.value.reduce(
    (result: Pick<MultiSelectOption, "id">[], { id, isPrimary }) => {
      if (isPrimary) {
        result.push({ id });
      }
      return result;
    },
    []
  )
);

const isSendingDisabled = computed(
  () =>
    !smsText.value || !selectedRecipients.value.length || isMessageTooLong.value
);

const handleUpdateRecipients = (value: Pick<MultiSelectOption, "id">[]) => {
  selectedRecipients.value = value;
};

const handleCleanContentUpdate = (cleanContent: string) => {
  smsText.value = cleanContent;
  isMessageTooLong.value =
    Math.ceil(cleanContent.length / MAX_SMS_MESSAGE_LENGTH) > 10;
};

const { fetchWrapper: sendSms, loading } = usePromiseWrapper(async () => {
  const applicationsPayload = selectedRecipients.value.reduce(
    (result: Record<string, string[]>, curr) => {
      const recipientOption = recipientOptions.value.find(
        (option) => option.id === curr.id
      );
      if (!recipientOption) {
        return result;
      }
      const { applicationId, telephone } = recipientOption;
      if (!result[applicationId]) {
        result[applicationId] = [];
      }
      result[applicationId].push(telephone);
      return result;
    },
    {}
  );

  const payload = {
    applications: applicationsPayload,
    message: smsText.value
  };

  await communicationService.sendSms(payload);
  emit("close-dialog");
});
</script>
<style scoped>
.pill-item {
  @apply text-xs p-2 pr-1 bg-disabled bg-opacity-50 text-headline rounded-full flex items-center space-x-2;
}
.remove-button {
  @apply w-5 h-5 rounded-full bg-disabled-medium text-white inline-flex items-center justify-center;
}

.send-button {
  @apply px-2 w-[34px] ml-auto;
}

:deep() {
  .multiselect-dropdown {
    @apply mb-0;
  }

  .multiselect-dropdown .dropdown-options {
    @apply max-h-30;
  }

  .send-button[disabled] {
    @apply bg-disabled-medium;
  }
}
</style>
